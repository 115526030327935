import React, { useEffect } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { blogApiSlice } from '../../Redux/Slices/Blogs/blogs'

const BlogPage = () => {
    const blogList = useSelector((state) => state?.blogList?.blogData)
    const dispatch = useDispatch()
    console.log('blog list', blogList)

    useEffect(() => {
        dispatch(blogApiSlice())
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <Header />
            <div className="main">
                <section>
                    <div className="BlogsArea pb-5">
                        <div className="container">
                            <div className='BlogBorderRadius2'>
                                <div className='row'>
                                    {
                                        blogList?.data?.result?.map((item) => {
                                            return (
                                                <div className="col-md-4">
                                                    <Link to='/blogDetail' state={item}>
                                                        <div className="BlogBox Last">
                                                            <figure>
                                                                <img src={item?.image} height={208} width={290} />
                                                            </figure>
                                                            <figcaption>
                                                                <span>{item?.categories?.[0]?.name}</span>
                                                                <p>Latest blog . {moment(item?.createdAt).format('DD/MM/YYYY')}</p>
                                                                <h3>{item?.name}</h3>
                                                            </figcaption>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
            <Footer />
        </div>
    )
}

export default BlogPage