import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

const initialState = {
    time: "30",
    price: ""
}
const BookingFirstPage = () => {
    const [iState, updateState] = useState(initialState)
    const { time } = iState
    const { state } = useLocation()
    const handleTimeChange = (time) => {
        updateState({
            ...iState,
            "time": time,
        })
    }
    const calculatePrice = () => {
        if (time === '30') {
            return state?.priceFor30Min
        }
        if (time === '45') {
            return state?.priceFor45Min
        }
        if (time === '60') {
            return state?.priceFor60Min
        }
    }
    console.log('state in booking first page', state)
    console.log('time', time)
    return (
        <div style={{ background: "#f6f7ff", padding: "10px", color: "#000", fontSize: "13px" }}>
            <div class="StepBoxEnd">
                <ul>
                    <li class="Current">
                        <span class="CircleImg">
                            <img src={require('../../Assets/images/endUserImages/first-img.png')} /></span>
                        <span class="Text">Booking info</span>
                    </li>
                    <li>
                        <span class="Circle Default"></span>
                        <span class="Text">Calendar Availability</span>
                    </li>
                    <li>
                        <span class="Circle Default"></span>
                        <span class="Text">Basic Details</span>
                    </li>
                    <li>
                        <span class="Circle Default"></span>
                        <span class="Text">Payment</span>
                    </li>
                </ul>
            </div>
            <div className="SmallWrapperBoxEnd">
                <div className="ConsultantService">
                    <div className="Heading">
                        <h3>{state?.serviceName} Service</h3>
                        <p>
                            {
                                state?.description
                            }
                        </p>
                    </div>
                    <div className="ServiceDetailsEnd">
                        <div className="SdLeft">
                            <h5>Service Details:</h5>
                            <ul>
                                {
                                    state?.details?.map((item) => {
                                        return (<li>
                                            <i className="fa-solid fa-check" />
                                            {item}
                                        </li>)
                                    })
                                }

                                {/* <li>
                                    <i className="fa-solid fa-check" />
                                    Guidance on compability
                                </li>
                                <li>
                                    <i className="fa-solid fa-check" />
                                    Analysis of astrological aspects related to marriage
                                </li> */}
                            </ul>
                        </div>
                        <div className="SdRight">
                            <ul>
                                <li>
                                    <a className={time === "30" ? 'active' : null} onClick={() => handleTimeChange('30')}>30 Mins</a>
                                </li>
                                <li>
                                    <a className={time === "45" ? 'active' : null} onClick={() => handleTimeChange('45')}>
                                        45 Mins
                                    </a>
                                </li>
                                <li>
                                    <a className={time === "60" ? 'active' : null} onClick={() => handleTimeChange('60')}>60 Mins</a>
                                </li>
                            </ul>
                            <h6>Pricing:</h6>
                            <h1>
                                {calculatePrice()}<span>/call</span>
                            </h1>
                        </div>
                    </div>
                    <div className="ChooseOptionAreaEnd">
                        <h4>
                            Choose an option to proceed:{" "}
                            <span>
                                <img src={require('../../Assets/images/endUserImages/info-circle.png')} />
                            </span>
                        </h4>
                        {
                            state?.serviceOption?.map((item) => {
                                return (<article>
                                    <label className="Radio">
                                        <input type="radio" name="Excellent" />
                                        <span className="checkmark" />
                                    </label>
                                    <aside>
                                        <div>
                                            <h6>{item?.optionName}</h6>
                                            <p>{item?.description}</p>
                                        </div>
                                        <span className="RadioSpan" />
                                        <figure>
                                            <img src={item?.icon} width={56} height={56} />
                                        </figure>
                                    </aside>
                                </article>)
                            })
                        }

                        {/* <article>
                            <label className="Radio">
                                <input type="radio" name="Excellent" />
                                <span className="checkmark" />
                            </label>
                            <aside>
                                <div>
                                    <h6>Know Your Marriage Profile</h6>
                                    <p>A brief description of this option.</p>
                                </div>
                                <figure>
                                    <img src="images/img-2.png" />
                                </figure>
                            </aside>
                        </article> */}
                    </div>
                    <div className="col-lg-6 mx-auto">
                        <div className="Buttons">
                            <Link className="ContinueBtn" to='/bookingSecondePage'>
                                Continue
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default BookingFirstPage