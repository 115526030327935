import React from 'react'

const NoShowAppointments = () => {
    return (
        <div><div className="tab-pane" id="NoShow">
            <div className="TitleBox justify-content-end m-0">
                <a
                    href="javascript:void(0);"
                    style={{ marginTop: "-85px" }}
                    className="FilterIcon"
                    data-toggle="modal"
                    data-target="#FilterModal"
                >
                    <span>
                        <img src="images/filter.png" />
                    </span>{" "}
                    Filter
                </a>
            </div>
            <div>
                <div className="TableList">
                    <table style={{ width: "140%" }}>
                        <thead>
                            <tr>
                                <th>Sr No.</th>
                                <th>Service Name</th>
                                <th>Date &amp; Time</th>
                                <th>Call Type &amp; Duration</th>
                                <th>Astrologer Name</th>
                                <th>Notes</th>
                                <th>No Show From</th>
                                <th>Refund Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1</td>
                                <td>Detailed Kundli Report</td>
                                <td>25/04/2024, 09:00 AM</td>
                                <td>Online (Video) 30 min</td>
                                <td>Ram Singh</td>
                                <td>When will i get married..</td>
                                <td>Customer</td>
                                <td>To be Refunded</td>
                                <td>
                                    <div className="ButtomActions">
                                        <span className="Download New">
                                            <img src="images/document-download.svg" />
                                            Download
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>2</td>
                                <td>Detailed Kundli Report</td>
                                <td>25/04/2024, 09:00 AM</td>
                                <td>Online (Video) 30 min</td>
                                <td>Ram Singh</td>
                                <td>When will i get married..</td>
                                <td>Customer</td>
                                <td>To be Refunded</td>
                                <td>
                                    <div className="ButtomActions">
                                        <span className="Download New">
                                            <img src="images/document-download.svg" />
                                            Download
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>3</td>
                                <td>Detailed Kundli Report</td>
                                <td>25/04/2024, 09:00 AM</td>
                                <td>Online (Video) 30 min</td>
                                <td>Ram Singh</td>
                                <td>When will i get married..</td>
                                <td>Customer</td>
                                <td>To be Refunded</td>
                                <td>
                                    <div className="ButtomActions">
                                        <span className="Download New">
                                            <img src="images/document-download.svg" />
                                            Download
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>4</td>
                                <td>Detailed Kundli Report</td>
                                <td>25/04/2024, 09:00 AM</td>
                                <td>Online (Video) 30 min</td>
                                <td>Ram Singh</td>
                                <td>When will i get married..</td>
                                <td>Customer</td>
                                <td>To be Refunded</td>
                                <td>
                                    <div className="ButtomActions">
                                        <span className="Download New">
                                            <img src="images/document-download.svg" />
                                            Download
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>5</td>
                                <td>Detailed Kundli Report</td>
                                <td>25/04/2024, 09:00 AM</td>
                                <td>Online (Video) 30 min</td>
                                <td>Ram Singh</td>
                                <td>When will i get married..</td>
                                <td>Customer</td>
                                <td>To be Refunded</td>
                                <td>
                                    <div className="ButtomActions">
                                        <span className="Download New">
                                            <img src="images/document-download.svg" />
                                            Download
                                        </span>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>6</td>
                                <td>Detailed Kundli Report</td>
                                <td>25/04/2024, 09:00 AM</td>
                                <td>Online (Video) 30 min</td>
                                <td>Ram Singh</td>
                                <td>When will i get married..</td>
                                <td>Customer</td>
                                <td>To be Refunded</td>
                                <td>
                                    <div className="ButtomActions">
                                        <span className="Download New">
                                            <img src="images/document-download.svg" />
                                            Download
                                        </span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div></div>
    )
}

export default NoShowAppointments