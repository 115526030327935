import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { addFamilyMemberApiSlice, deleteFamilyMemberApi, editFamilyMemberApi, FamilyMemberListApiSlice } from '../../../Redux/Slices/Profile/AddFamilyMember'
import { useDispatch, useSelector } from 'react-redux'

const initialState = {
    addFamilyMemberModal: false,
    editFamilyMemberModal: false,
    id: "",
    fullname: "",
    dateOfBirth: "",
    timeOfBirth: "",
    birthPlace: "",
    relation: "",
    errors: {}
}
const AddFamilyMember = () => {
    const [iState, updateState] = useState(initialState)
    const { addFamilyMemberModal, fullname, dateOfBirth, timeOfBirth, birthPlace, relation, editFamilyMemberModal, id, errors } = iState
    const relationlist = ['Self', 'Wife', 'Son', 'Daughter', 'Brother']
    const dispatch = useDispatch()
    const familymemlist = useSelector((state) => state?.familyMemberList)
    console.log('in family')
    const openAddFamilyMemberModal = () => {
        updateState({
            ...iState,
            addFamilyMemberModal: true
        })
    }

    const CloseModal = () => {
        updateState(initialState)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        updateState({
            ...iState,
            [name]: value
        })
    }

    const handleAddFamilyMember = () => {
        if (handleValidation()) {
            const data = {
                name: fullname,
                dob: dateOfBirth,
                birthPlace: birthPlace,
                birthTime: timeOfBirth,
                relation: relation
            }
            dispatch(addFamilyMemberApiSlice(data)).then((res) => {
                console.log('Respone of add mamber', res)
                if (res?.payload?.status === 200) {
                    updateState(initialState)
                    // call list api
                    dispatch(FamilyMemberListApiSlice())
                }
            })
        }

    }

    const openEditFamilyMemberModal = (item) => {
        updateState({
            ...iState,
            editFamilyMemberModal: true,
            id: item?._id,
            fullname: item?.name,
            dateOfBirth: item?.dob,
            birthPlace: item?.birthPlace,
            timeOfBirth: item?.birthTime,
            relation: item?.relation
        })
    }

    const handleSave = () => {
        if (handleValidation()) {
            const data = {
                memberId: id,
                name: fullname,
                dob: dateOfBirth,
                birthPlace: birthPlace,
                birthTime: timeOfBirth,
                relation: relation
            }
            dispatch(editFamilyMemberApi(data)).then((res) => {
                if (res?.payload?.status === 200) {
                    updateState(initialState)
                    dispatch(FamilyMemberListApiSlice())
                }
            })
        }
    }

    const handleDelete = (id) => {
        const data = {
            memberId: id
        }
        dispatch(deleteFamilyMemberApi(data)).then((res) => {
            if (res?.payload?.status === 200) {
                updateState(initialState)
                dispatch(FamilyMemberListApiSlice())
            }
        })
    }

    const handleDeleteFromModal = () => {
        const data = {
            memberId: id
        }
        dispatch(deleteFamilyMemberApi(data)).then((res) => {
            if (res?.payload?.status === 200) {
                updateState(initialState)
                dispatch(FamilyMemberListApiSlice())
            }
        })
    }

    const handleValidation = () => {
        // write validation code ///
        let isValid = true
        const error = {}

        if (!fullname) {
            error.fullnameError = "Please enter your full name"
            isValid = false
        }
        if (!dateOfBirth) {
            error.dateOfBirthError = "Please enter your date of birth"
            isValid = false
        }
        if (!timeOfBirth) {
            error.timeOfBirthError = "Please enter your time of birth"
            isValid = false
        }
        if (!birthPlace) {
            error.birthPlaceError = "Please enter place of birth"
            isValid = false
        }
        if (!relation) {
            error.relationError = "Please select relation"
            isValid = false
        }
        updateState({
            ...iState,
            errors: error
        })
        return isValid
    }

    useEffect(() => {
        dispatch(FamilyMemberListApiSlice())
    }, [])
    return (
        <div>
            <h3>Family Member</h3>
            <div>
                <button
                    className="AddFamilyMemberBtnEnd"
                    onClick={openAddFamilyMemberModal}
                >
                    {" "}
                    <i className="fa-solid fa-plus" /> Add Family Member
                </button>
            </div>
            <aside>
                <div
                    className="FamilyDetailsAside"
                >
                    {
                        familymemlist?.familyMemberList?.data?.map((item) => {
                            console.log('list item', item)
                            return (

                                <div
                                    className="FamilyDetailBox"
                                >
                                    <figure>
                                        {/* <img src="images/profile.jpg" /> */}
                                    </figure>
                                    <figcaption>
                                        <h3>{item?.name}</h3>
                                        <p>{item?.relation}</p>
                                    </figcaption>
                                    <div className="Overlay">
                                        <a className="Green" onClick={() => openEditFamilyMemberModal(item)}>
                                            <i className="fa-solid fa-pen-to-square" />
                                        </a>
                                        <a className="Red" onClick={() => handleDelete(item?._id)}>
                                            <i className="fa-solid fa-trash" />
                                        </a>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

            </aside>
            <Modal
                show={addFamilyMemberModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category">
                        <a onClick={CloseModal} className="CloseModal" data-dismiss="modal">
                            ×
                        </a>
                        <h3>Add Family Member</h3>
                        <div className="ModalFormArea">
                            <div className="form-group">
                                <label>Full Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Full Name"
                                    name='fullname'
                                    value={fullname}
                                    onChange={handleChange}
                                />
                                <span style={{ color: 'red' }}>{errors.fullnameError}</span>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-6">
                                    <label>DOB</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name='dateOfBirth'
                                        value={dateOfBirth}
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.dateOfBirthError}</span>
                                </div>
                                <div className="form-group col-lg-6">
                                    <label>Time of Birth</label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        name='timeOfBirth'
                                        value={timeOfBirth}
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.timeOfBirthError}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Birth Place</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Birth place"
                                    name='birthPlace'
                                    value={birthPlace}
                                    onChange={handleChange}
                                />
                                <span style={{ color: 'red' }}>{errors.birthPlaceError}</span>
                            </div>
                            <div className="form-group">
                                <label>Relation</label>
                                <select className="form-control" name='relation' value={relation} onChange={handleChange}>
                                    <option>select relation</option>
                                    {
                                        relationlist?.map((item) => {
                                            return (<option value={item}>{item}</option>)
                                        })
                                    }
                                </select>
                                <span style={{ color: 'red' }}>{errors.relationError}</span>
                            </div>
                            <div className="Buttons">
                                <button
                                    type="button"
                                    className="Button AddFamilyScript ml-0"
                                    onClick={handleAddFamilyMember}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <Modal
                show={editFamilyMemberModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category">
                        <a onClick={CloseModal} className="CloseModal" data-dismiss="modal">
                            ×
                        </a>
                        <h3>Add Family Member</h3>
                        <div className="ModalFormArea">
                            <div className="form-group">
                                <label>Full Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Full Name"
                                    name='fullname'
                                    value={fullname}
                                    onChange={handleChange}
                                />
                                <span style={{ color: 'red' }}>{errors.fullnameError}</span>
                            </div>
                            <div className="row">
                                <div className="form-group col-lg-6">
                                    <label>DOB</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name='dateOfBirth'
                                        value={dateOfBirth}
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.dateOfBirthError}</span>
                                </div>
                                <div className="form-group col-lg-6">
                                    <label>Time of Birth</label>
                                    <input
                                        type="time"
                                        className="form-control"
                                        name='timeOfBirth'
                                        value={timeOfBirth}
                                        onChange={handleChange}
                                    />
                                    <span style={{ color: 'red' }}>{errors.timeOfBirthError}</span>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Birth Place</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Birth place"
                                    name='birthPlace'
                                    value={birthPlace}
                                    onChange={handleChange}
                                />
                                <span style={{ color: 'red' }}>{errors.birthPlaceError}</span>
                            </div>
                            <div className="form-group">
                                <label>Relation</label>
                                <select className="form-control" name='relation' value={relation} onChange={handleChange}>
                                    <option>select relation</option>
                                    {
                                        relationlist?.map((item) => {
                                            return (<option value={item}>{item}</option>)
                                        })
                                    }
                                </select>
                                <span style={{ color: 'red' }}>{errors.relationError}</span>
                            </div>
                            <div className="Buttons TwoButtons">
                                <button type="button" className="Button" onClick={handleSave}>
                                    Save
                                </button>
                                <button type="button" className="Button Red" onClick={handleDeleteFromModal}>
                                    Delete this family member
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AddFamilyMember