import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const progressApi = createAsyncThunk(
    "progressApi/getprogressApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `profile-percentage?userId=${payload}`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    progressData: [],
    status: false
}
const ProgressSlice = createSlice({
    name: 'progressSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(progressApi.pending, (state) => {
                state.status = true;
            })
            .addCase(progressApi.fulfilled, (state, action) => {
                state.status = false;
                state.progressData = action.payload;
            })
            .addCase(progressApi.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
    }
})

export default ProgressSlice.reducer;