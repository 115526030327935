import React from 'react'

const Panchang = () => {
    return (
        <div
        //className='tab-content'
        ><div className="tab-pane" id="Panchang">
                <div className="container">
                    <div className="FreeKundliHighlights">
                        <h1>Panchang</h1>
                        <ul>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-1.png')} />
                                </span>
                                100% Accurate Data
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-2.png')} />
                                </span>
                                Today's Panchang
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-3.png')} />
                                </span>
                                Determine Auspicious Timings
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-4.png')} />
                                </span>
                                Understand Planetary Positions
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-5.png')} />
                                </span>
                                Designed by Expert and Experienced Astrologers
                            </li>
                        </ul>
                        <figure>
                            <img src={require('../../Assets/images/menimg.png')} />
                            {/* <img src="images/menimg.png" /> */}
                        </figure>
                    </div>
                </div>
                <section>
                    <div className="PanchangArea">
                        <div className="container">
                            <div className="EnterDateLocation">
                                <h1>Panchang</h1>
                                <div className="EnterDateBox">
                                    <h5>Enter Date &amp; Location To Find Panchang</h5>
                                    <div className="CommonForm">
                                        <form>
                                            <div className="row">
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <h6>Select Date</h6>
                                                        <input type="date" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="form-group">
                                                        <h6>Select Location</h6>
                                                        <select className="form-control">
                                                            <option>select</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group d-flex align-items-end justify-content-end h-100">
                                                        <a className="SubmitBtn">
                                                            Submit
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="FreeKundliFiveTab mt-4">
                            <ul className="nav nav-pills nav-fill">
                                <li className="nav-item">
                                    <a href="#Today" data-toggle="tab" className="nav-link active">
                                        Today
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#Tomorrow" data-toggle="tab" className="nav-link">
                                        Tomorrow
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#Calendar" data-toggle="tab" className="nav-link">
                                        Calendar
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="Today">
                                    <div className="PanchangTable-section-title pt-0">
                                        <h1>Today's Panchang</h1>
                                    </div>
                                    <div className="PanchangTable px-1">
                                        <div className="PanchangTableDetails">
                                            <figure>
                                                <img src={require('../../Assets/images/swastik.png')} />

                                                <figcaption>Thu, 18 April 2024</figcaption>
                                            </figure>
                                            <figure>
                                                <img src={require('../../Assets/images/location.png')} />

                                                <figcaption>Noida, UP</figcaption>
                                            </figure>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 my-3">
                                                <div className="BasicDetailsTable h-100">
                                                    <table className="h-100">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Panchang</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Tithi</td>
                                                                <td>Shukla-Dashami</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Nakshatra</td>
                                                                <td>Magha</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Karana</td>
                                                                <td>Gara</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Paksha</td>
                                                                <td>Shukla-Paksha</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Yoga</td>
                                                                <td>Dand till 12:42:43 AM, 19 April</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Day</td>
                                                                <td>Thursday</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 my-3">
                                                <div className="BasicDetailsTable h-100">
                                                    <table className="h-100">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Sun &amp; Moon Calculations</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    Sun Rise <br /> Sun Set
                                                                </td>
                                                                <td>
                                                                    05:50:56 AM <br /> 05:50:56 AM
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Moon Rise <br /> Moon Set
                                                                </td>
                                                                <td>
                                                                    05:50:56 AM <br /> 05:50:56 AM
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Moon Sign</td>
                                                                <td>Leo</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ritu</td>
                                                                <td>Vasant</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="BasicDetailsTable mt-4">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Hindu Month &amp; Year</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Shaka Samvat</td>
                                                                <td>1946</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Vikram Samvat</td>
                                                                <td>2081</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Month Amanta</td>
                                                                <td>Chairtra</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Month Purnimanta</td>
                                                                <td>Chairtra</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="BasicDetailsTable mt-4">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>
                                                                    Inauspicious Timings (Ashubha Muhurat)
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Rahu Kaal</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Yamaganda</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Gulika Kaal</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="BasicDetailsTable mt-3">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>
                                                                    Auspicious Timings (Shubha Muhurat)
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Abhijit</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="LagnaBox mt-4">
                                                    <aside>
                                                        <h4>Lagan Chart</h4>
                                                    </aside>
                                                    <article>
                                                        <figure>
                                                            <img src={require('../../Assets/images/panchage.png')} />
                                                        </figure>
                                                    </article>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="LagnaBox mt-4">
                                                    <aside>
                                                        <h4>Moon Chart</h4>
                                                    </aside>
                                                    <article>
                                                        <figure>
                                                            <img src={require('../../Assets/images/panchage.png')} />
                                                        </figure>
                                                    </article>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="Tomorrow">
                                    <div className="PanchangTable px-1">
                                        <div className="PanchangTableDetails">
                                            <figure>
                                                <img src={require('../../Assets/images/swastik.png')} />
                                                <figcaption>Thu, 18 April 2024</figcaption>
                                            </figure>
                                            <figure>
                                                <img src={require('../../Assets/images/location.png')} />
                                                <figcaption>Noida, UP</figcaption>
                                            </figure>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 my-3">
                                                <div className="BasicDetailsTable h-100">
                                                    <table className="h-100">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Panchang</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Tithi</td>
                                                                <td>Shukla-Dashami</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Nakshatra</td>
                                                                <td>Magha</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Karana</td>
                                                                <td>Gara</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Paksha</td>
                                                                <td>Shukla-Paksha</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Yoga</td>
                                                                <td>Dand till 12:42:43 AM, 19 April</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Day</td>
                                                                <td>Thursday</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 my-3">
                                                <div className="BasicDetailsTable h-100">
                                                    <table className="h-100">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Sun &amp; Moon Calculations</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    Sun Rise <br /> Sun Set
                                                                </td>
                                                                <td>
                                                                    05:50:56 AM <br /> 05:50:56 AM
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Moon Rise <br /> Moon Set
                                                                </td>
                                                                <td>
                                                                    05:50:56 AM <br /> 05:50:56 AM
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Moon Sign</td>
                                                                <td>Leo</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ritu</td>
                                                                <td>Vasant</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="BasicDetailsTable mt-4">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Hindu Month &amp; Year</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Shaka Samvat</td>
                                                                <td>1946</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Vikram Samvat</td>
                                                                <td>2081</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Month Amanta</td>
                                                                <td>Chairtra</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Month Purnimanta</td>
                                                                <td>Chairtra</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="BasicDetailsTable mt-4">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>
                                                                    Inauspicious Timings (Ashubha Muhurat)
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Rahu Kaal</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Yamaganda</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Gulika Kaal</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="BasicDetailsTable mt-3">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>
                                                                    Auspicious Timings (Shubha Muhurat)
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Abhijit</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="LagnaBox mt-4">
                                                    <aside>
                                                        <h4>Lagan Chart</h4>
                                                    </aside>
                                                    <article>
                                                        <figure>
                                                            <img src={require('../../Assets/images/panchage.png')} />
                                                        </figure>
                                                    </article>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="LagnaBox mt-4">
                                                    <aside>
                                                        <h4>Moon Chart</h4>
                                                    </aside>
                                                    <article>
                                                        <figure>
                                                            <img src={require('../../Assets/images/panchage.png')} />
                                                        </figure>
                                                    </article>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="Calendar">
                                    <div className="PanchangTable px-1">
                                        <div className="PanchangTableDetails">
                                            <figure>
                                                <img src={require('../../Assets/images/swastik.png')} />
                                                <figcaption>Thu, 18 April 2024</figcaption>
                                            </figure>
                                            <figure>
                                                <img src={require('../../Assets/images/location.png')} />
                                                <figcaption>Noida, UP</figcaption>
                                            </figure>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6 my-3">
                                                <div className="BasicDetailsTable h-100">
                                                    <table className="h-100">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Panchang</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Tithi</td>
                                                                <td>Shukla-Dashami</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Nakshatra</td>
                                                                <td>Magha</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Karana</td>
                                                                <td>Gara</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Paksha</td>
                                                                <td>Shukla-Paksha</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Yoga</td>
                                                                <td>Dand till 12:42:43 AM, 19 April</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Day</td>
                                                                <td>Thursday</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 my-3">
                                                <div className="BasicDetailsTable h-100">
                                                    <table className="h-100">
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Sun &amp; Moon Calculations</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    Sun Rise <br /> Sun Set
                                                                </td>
                                                                <td>
                                                                    05:50:56 AM <br /> 05:50:56 AM
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Moon Rise <br /> Moon Set
                                                                </td>
                                                                <td>
                                                                    05:50:56 AM <br /> 05:50:56 AM
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>Moon Sign</td>
                                                                <td>Leo</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Ritu</td>
                                                                <td>Vasant</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="BasicDetailsTable mt-4">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>Hindu Month &amp; Year</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Shaka Samvat</td>
                                                                <td>1946</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Vikram Samvat</td>
                                                                <td>2081</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Month Amanta</td>
                                                                <td>Chairtra</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Month Purnimanta</td>
                                                                <td>Chairtra</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="BasicDetailsTable mt-4">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>
                                                                    Inauspicious Timings (Ashubha Muhurat)
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Rahu Kaal</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Yamaganda</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Gulika Kaal</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="BasicDetailsTable mt-3">
                                                    <table>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan={2}>
                                                                    Auspicious Timings (Shubha Muhurat)
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>Abhijit</td>
                                                                <td>01:53:12 PM to 03:29:38 PM</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="LagnaBox mt-4">
                                                    <aside>
                                                        <h4>Lagan Chart</h4>
                                                    </aside>
                                                    <article>
                                                        <figure>
                                                            <img src={require('../../Assets/images/panchage.png')} />
                                                        </figure>
                                                    </article>
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="LagnaBox mt-4">
                                                    <aside>
                                                        <h4>Moon Chart</h4>
                                                    </aside>
                                                    <article>
                                                        <figure>
                                                            <img src={require('../../Assets/images/panchage.png')} />
                                                        </figure>
                                                    </article>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="ConsultSectionOuter mb-0">
                        <div className="container">
                            <div className="ConsultSection">
                                <div className="ConsultLeft">
                                    <h3>Consult to get an In-depth analysis of Your Kundli</h3>
                                    <p>
                                        Call or chat with experienced Vedic Astrologers to get accurate
                                        predictions about your future and <br /> personalized solutions
                                        for your problems.
                                    </p>
                                </div>
                                <div className="ConsultRight">
                                    <a >Match Kundli</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="GetDetailedchart">
                        <div className="container">
                            <div className="BoxContent2">
                                <h1>
                                    What is Panchang and it's significance <br />
                                    (Static content)
                                </h1>
                                <p>
                                    The term "Panchang" is derived from Sanskrit, where "Panch" means
                                    "five" and "Ang" means "limb" or "part." Thus, Panchang refers to
                                    the traditional Hindu calendar, which consists of five parts or
                                    components:
                                </p>
                                <p>
                                    <span>Tithi:</span> Tithi refers to the lunar phase or the position
                                    of the moon with respect to the sun and is essential for determining
                                    auspicious and inauspicious timings for various activities.
                                </p>
                                <p>
                                    <span>Vara:</span> Vara represents the day of the week, which is
                                    significant in Hindu astrology for scheduling events, rituals, and
                                    religious observances.
                                </p>
                                <p>
                                    <span>Nakshatra:</span> Nakshatra refers to the lunar mansion or
                                    constellation through which the moon passes on a particular day.
                                    Each Nakshatra has its own characteristics and is used for timing
                                    events and predicting outcomes.
                                </p>
                                <p>
                                    <span>Yoga:</span> Yoga is a combination of specific degrees of the
                                    sun and the moon and indicates auspicious or inauspicious periods
                                    for various activities.
                                </p>
                                <p>
                                    <span>Karana:</span> Karana is half of a Tithi and is used for
                                    determining the quality of time for initiating tasks and activities.
                                </p>
                                <p>
                                    The significance of Panchang in astrology lies in its ability to
                                    provide comprehensive astrological information and guidance for
                                    various aspects of life:
                                </p>
                                <p>
                                    <span>Auspicious Timings:</span> Panchang helps in identifying
                                    auspicious timings (Muhurat) for important life events such as
                                    weddings, ceremonies, housewarming, and other significant activities
                                    based on the alignment of celestial bodies.
                                </p>
                                <p>
                                    <span>Astrological Analysis:</span> It serves as a tool for
                                    astrologers to make predictions and analyze the influence of
                                    celestial bodies on individuals, societies, and events. By studying
                                    the Panchang, astrologers can assess the planetary positions and
                                    their impact on human affairs.
                                </p>
                                <p>
                                    <span>Religious Observances:</span> Panchang includes information
                                    about religious festivals, fasting days, and other important dates
                                    in the Hindu calendar, facilitating religious observances and
                                    rituals according to astrological significance.
                                </p>
                                <p>
                                    <span>Personal Guidance:</span> Individuals consult Panchang for
                                    personal guidance regarding auspicious dates for starting new
                                    ventures, signing contracts, traveling, and other activities, based
                                    on astrological considerations.
                                </p>
                                <p>
                                    <span>Spiritual Practices:</span> Panchang provides guidance for
                                    spiritual practices such as meditation, mantra chanting, and
                                    pilgrimage, suggesting favorable times for undertaking such
                                    activities.
                                </p>
                                <p>
                                    Overall, Panchang plays a crucial role in Hindu astrology and
                                    culture, providing a framework for timing events, making decisions,
                                    and understanding the cosmic influences on human life.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="FaqAera">
                        <div className="container">
                            <div className="section-title">
                                <h1>Frequently asked questions</h1>
                            </div>
                            <div className="StaticArea">
                                <div id="accordion">
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse21"
                                            aria-expanded="false"
                                        >
                                            <h4>Q 1. What is Panchang?</h4>
                                        </div>
                                        <div id="collapse21" className="panel-collapse collapse in">
                                            <div className="card-body">
                                                <p>
                                                    A Panchang is a traditional Hindu calendar that provides
                                                    detailed information about the days, months, and
                                                    astronomical positions. It includes details about tithis
                                                    (lunar days), nakshatras (constellations), yogas, karanas,
                                                    and planetary positions, helping people choose auspicious
                                                    times for various activities.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse22"
                                        >
                                            <h4>Q 2. How is a Panchang used in daily life?</h4>
                                        </div>
                                        <div id="collapse22" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse23"
                                        >
                                            <h4>Q 3. What is the significance of Tithi in a Panchang?</h4>
                                        </div>
                                        <div id="collapse23" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse24"
                                        >
                                            <h4>
                                                Q 4. What Happens When We Do Any Work During Inauspicious
                                                Muhurat?
                                            </h4>
                                        </div>
                                        <div id="collapse24" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse25"
                                        >
                                            <h4>
                                                Q 5. How do planetary positions in a Panchang affect
                                                decisions?
                                            </h4>
                                        </div>
                                        <div id="collapse25" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse26"
                                        >
                                            <h4>Q 6. What is the significance of Tithi in a Panchang?</h4>
                                        </div>
                                        <div id="collapse26" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Panchang