import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { blogApiSlice } from '../../Redux/Slices/Blogs/blogs'
import moment from 'moment'

const Blogs = () => {
    const dispatch = useDispatch()
    const blogs = useSelector((state) => state?.blogList?.blogData)
    console.log('blogs', blogs)

    useEffect(() => {
        dispatch(blogApiSlice())
    }, [])
    return (
        <div>
            <div className="BlogsArea">
                <div className="container">
                    <div className="section-title">
                        <h1>Blog &amp; Articles</h1>
                        <p>
                            Select your service, enter your birth details, and embark on an
                            enlightening journey. Our intuitive <br /> platform connects you
                            with expert astrologers for personalised insights and guidance.
                        </p>
                    </div>
                    <div className='BlogBorderRadius'>
                        <div
                            className="row"
                        >
                            {
                                blogs?.data?.result?.length > 0 ?
                                    blogs?.data?.result?.slice(0, 3)?.map((item) => {
                                        return (<div className="col-md-4">
                                            <Link to="/blogDetail" state={item}>
                                                <div className="BlogBox">
                                                    <figure>
                                                        <img src={item?.image} height={208} width={290} />
                                                    </figure>
                                                    <figcaption>
                                                        <span>{item?.categories?.[0]?.name}</span>
                                                        <p>Latest blog . {moment(item?.createdAt).format('DD/MM/YYYY')}</p>
                                                        <h3>{item?.name}</h3>
                                                    </figcaption>
                                                </div>
                                            </Link>
                                        </div>)
                                    }) : null
                            }

                        </div>
                        <div className="text-center">
                            <Link to='/blogPage' className="ViewAllBlogsBtn">
                                View all Blogs <i className="fa-solid fa-chevron-right" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Blogs