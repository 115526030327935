import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const addFamilyMemberApiSlice = createAsyncThunk(
    "addFamilyMember/postaddFamilyMember",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `add-family-member`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//==================== list of family member ==================//

export const FamilyMemberListApiSlice = createAsyncThunk(
    "FamilyMemberList/getaddFamilyMemberList",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `list-family-member`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    familyMemberList: [],
    status: false
}
const familyMemberListSlice = createSlice({
    name: 'familyListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(FamilyMemberListApiSlice.pending, (state) => {
                state.status = true;
            })
            .addCase(FamilyMemberListApiSlice.fulfilled, (state, action) => {
                state.status = false;
                state.familyMemberList = action.payload;
            })
            .addCase(FamilyMemberListApiSlice.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            });
    }
})

export default familyMemberListSlice.reducer;

//================== Delete Member===============//
export const deleteFamilyMemberApi = createAsyncThunk(
    "deleteFamilyMember/postdeleteFamilyMember",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `delete-family-member`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//=================== edit family member ==============//
export const editFamilyMemberApi = createAsyncThunk(
    "editFamilyMember/posteditFamilyMember",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `edit-familyMember`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);