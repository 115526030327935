import React, { act, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { GoogleLogin } from '@react-oauth/google'
import { Link, useNavigate } from 'react-router-dom'
import GoogleLoginComponent from '../LoginFlow/GoogleLoginComponent'
import { useDispatch, useSelector } from 'react-redux'
import { loginApiSlice, userRegistration, verifyOtp } from '../../Redux/Slices/Login/Login'
import { toast } from 'react-toastify'
import OTPInput from 'react-otp-input'
import Profile from '../EndUser/Profile/Profile'
import LoggedInProfile from './LoggedInProfile'
import isLoggedIn from '../../Utils/IsLoggedIn'
import { premiumServicesApi } from '../../Redux/Slices/PremiumServices/PremiumServices'
import FacebookLoginButton from '../LoginFlow/FacebookLoginButton'
import { FaFacebook } from "react-icons/fa";
import { closeLoginModal, setLoggedInStatus } from '../../Redux/Slices/FooterLogin/FooterLogin'

const initialState = {
    loginModal: false,
    otpModal: false,
    userModal: false,
    sucessModal: false,
    phoneNumber: '',
    acceptTermsAndCondition: false,
    localOtp: "",
    name: "",
    email: "",
    loginState: "",
    googlephoneNumberModal: false,
    phoneNumberModal: false,
    errors: {}
}
const Header = () => {
    const [iState, updateState] = useState(initialState)
    const { otpModal, userModal, sucessModal, loginModal, phoneNumber, acceptTermsAndCondition, localOtp, name, email, phoneNumberModal, errors } = iState
    const [otp, setOtp] = useState("")
    const premiumserviceslist = useSelector((state) => state?.premiumServicesList.premiumServices)
    const { openLoginModal } = useSelector((state) => state?.footerLoginData)


    // console.log("premiumserviceslist", premiumserviceslist?.data?.result)
    const navigate = useNavigate()

    const dispatch = useDispatch()

    // hit mobile login api
    const handleOpendLoginModal = () => {
        updateState({
            loginModal: true,
        })
    }
    const handleCloseUserModal = () => {
        updateState({
            ...iState,
            otpModal: false,
            userModal: false,
            sucessModal: true
        })
    }
    //=================== Google login 
    const handleChange = (e) => {
        const { name, value, checked } = e.target;

        if (name === 'acceptTermsAndCondition') {
            updateState({
                ...iState,
                [name]: checked
            });
        }
        else if (name === 'phoneNumber') {
            if (Number(value) <= 9999999999 && Number(value) >= 0) {
                updateState({
                    ...iState,
                    [name]: value
                });
            } else {
                // Handle invalid phone number case
                // console.error('Invalid phone number');
            }
        } else {
            updateState({
                ...iState,
                [name]: value
            });
        }
    };

    const handleLoginWithMobileNumber = () => {
        console.log('in login with mobile number', phoneNumber, 'acceptTermsAndCondition', acceptTermsAndCondition)
        if (handleValidation() && acceptTermsAndCondition) {
            const data = {
                contact: phoneNumber
            }
            // dispatch api
            dispatch(loginApiSlice(data)).then((res) => {
                // console.log('res of otp api', res)
                if (res?.payload?.status === 200) {
                    toast.success('Otp sent sucessfully', {
                        position: 'top-right',
                        autoClose: 1000
                    })
                    updateState((prev) => {
                        // console.log('in update function ', res?.payload?.data?.otp);
                        return {
                            ...prev,
                            localOtp: Number(res?.payload?.data?.otp),
                            phoneNumber: Number(res?.payload?.data?.contact),
                            loginModal: false,
                            phoneNumberModal: false,
                            otpModal: true,
                        };
                    });
                    setOtp("")
                    // console.log('iState', iState)
                    // handleCloseLoginModal()
                }
            })
        }
    }

    const handleResend = () => {
        const data = {
            contact: phoneNumber
        }
        dispatch(loginApiSlice(data)).then((res) => {
            // console.log('res of otp api', res)
            if (res?.payload?.status === 200) {
                toast.success('Otp sent sucessfully', {
                    position: 'top-right',
                    autoClose: 1000
                })
                updateState((prev) => {
                    return {
                        ...prev,
                        localOtp: Number(res?.payload?.data?.otp),
                        phoneNumber: Number(res?.payload?.data?.contact),
                        loginModal: false,
                        otpModal: true,
                    };
                });
                setOtp("")
            }
        })
    }

    const handleVerifyOtp = () => {
        const data = {
            contact: Number(phoneNumber),
            otp: Number(otp)
        }
        dispatch(verifyOtp(data)).then((res) => {
            // console.log('Response', res)
            if (res?.payload?.status === 200) {
                toast.success('Otp Matched Sucessfully', {
                    position: 'top-right',
                    autoClose: 1000
                })
                // write code for login for if user is verified
                // console.log('user exist', res?.data?.userExist)
                if (res?.payload?.data?.userExist) {
                    window.localStorage.setItem('astroTechWeb', res?.payload?.data?.user?.jwtToken)
                    navigate('/', { replace: true }) // for new entry
                    dispatch(setLoggedInStatus(true));
                    updateState({
                        ...iState,
                        loginState: true
                    })
                    handleClose()
                } else {
                    updateState({
                        ...iState,
                        otpModal: false,
                        userModal: true,
                        localOtp: "",
                        otp: ""
                    })
                }

            }
        })
    }

    const handleUserRegistration = () => {
        const data = {
            contact: Number(phoneNumber),
            name: name,
            email: email
        }
        // console.log('data', data)
        if (name?.trim()) {
            dispatch(userRegistration(data)).then((res) => {
                // console.log('Response', res)
                if (res?.payload?.status === 200) {
                    toast.success('User Registered Sucessfully', {
                        position: 'top-right',
                        autoClose: 1000
                    })
                    window.localStorage.setItem('astroTechWeb', res?.payload?.data?.jwtToken)
                    dispatch(setLoggedInStatus(true));
                    handleCloseUserModal()
                }
            })
        }

    }

    const handleClose = () => {
        updateState(initialState)
        dispatch(closeLoginModal())
    }

    useEffect(() => {
        dispatch(premiumServicesApi())
    }, [])

    useEffect(() => {
        if (openLoginModal) {
            console.log('open login modal', openLoginModal)
            updateState({
                ...iState,
                loginModal: true

            })
        }

    }, [openLoginModal])

    const handleValidation = () => {
        let error = {}
        let isValid = true

        if (!phoneNumber) {
            error.phoneNumber = "Phone number is required"
            isValid = false
        }
        if (phoneNumber?.length < 10) {
            error.phoneNumber = "Phone number is invalid"
            isValid = false
        }
        if (!acceptTermsAndCondition) {
            error.acceptTermsAndCondition = "You must agree to terms and conditions"
            isValid = false
        }
        updateState({
            ...iState,
            errors: error
        })
        return isValid
    }

    // const handleValidationForEmail = () => {
    //     let error = {}
    //     let isValid = true
    //     if (!email) {
    //         error.email = "Email is required";
    //         isValid = false;
    //     } else if (!/\S+@\S+\.\S+/.test(email)) {
    //         error.email = "Email is invalid";
    //         isValid = false;
    //     }

    //     updateState({
    //         ...iState,
    //         errors: error
    //     })
    //     return isValid
    // }       email validation

    console.log('iState', iState)
    return (
        <div>
            <header>
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand" onClick={() => navigate('/')}>
                            <img src={require('../../Assets/images/Logo.png')} />
                        </a>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item">
                                    <Link className="nav-link" to='/aboutUs'>
                                        About Us
                                    </Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        Free Services&nbsp;
                                        <i className="fa-solid fa-chevron-down" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        <li>
                                            <Link to="/freeServices/freekundli/kundli" className="dropdown-item">
                                                Free Kundli
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/freeServices/horoscopes/""' className="dropdown-item">
                                                Horoscopes
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/freeServices/panchang/""' className="dropdown-item">
                                                Panchang
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to='/freeServices/numerology/""' className="dropdown-item">
                                                Numerology
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/freeServices/kundliMatching/matchMaking" className="dropdown-item">
                                                Kundli Matching
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <a
                                        className="nav-link"
                                        href="#"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        Premium Services&nbsp;
                                        <i className="fa-solid fa-chevron-down" />
                                    </a>
                                    <ul className="dropdown-menu">
                                        {/* Work here to reder dyanmic list of permium services */}
                                        {
                                            premiumserviceslist?.data?.result?.map((item) => {
                                                return (
                                                    <li>
                                                        <Link
                                                            to='/bookingFirstPage'
                                                            className="dropdown-item"
                                                            state={item}
                                                        >
                                                            {item?.serviceName}
                                                        </Link>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" to="/blogPage">
                                        Blog
                                    </Link>
                                </li>
                            </ul>

                            {
                                isLoggedIn() ?
                                    <LoggedInProfile iState callBack={updateState} /> :
                                    <div className="RightNavbar">
                                        <ul>
                                            <li className="nav-item">
                                                <a
                                                    className="nav-link AccountBtn"
                                                    onClick={handleOpendLoginModal}
                                                >
                                                    Login or Sign Up
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                            }
                        </div>
                    </nav>
                </div>
            </header>
            {/* Login */}
            <Modal
                show={loginModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category ModalLoginArea">
                        <a className="CloseModal"
                            onClick={handleClose}
                        >
                            ×
                        </a>
                        <h3>Login/Sign Up</h3>
                        <div className="ModalFormArea">
                            <div className="form-group">
                                <label>Mobile Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Mobile Number"
                                    name='phoneNumber'
                                    value={phoneNumber}
                                    onChange={handleChange}
                                //disabled={!acceptTermsAndCondition}
                                />
                                <span style={{ color: 'red', padding: '20px' }}>{errors?.phoneNumber}</span>
                            </div>
                            <div className="form-group">
                                <label className="CheckBox">
                                    I agree to Terms &amp; Conditions
                                    <input
                                        type="checkbox"
                                        name='acceptTermsAndCondition'
                                        checked={acceptTermsAndCondition}
                                        onChange={handleChange} />


                                    <br />
                                    <span style={{ color: 'red' }}>{errors?.acceptTermsAndCondition}</span>
                                    <span className="checkmark" />
                                </label>
                            </div>
                            <div className="Buttons">
                                <button
                                    type="button"
                                    className="Button FolderPermissionId ml-0"
                                    onClick={handleLoginWithMobileNumber}
                                >
                                    Log In
                                </button>
                            </div>
                            <div className="LogUsingOther">
                                <h2>Or sign up with</h2>
                                <div className="LoginSocialIcons">
                                    <div>


                                    </div>
                                    <ul>
                                        <li>
                                            <a href="javascript:void(0);">
                                                {" "}
                                                {/* <span>
                                                    <img src={require('../../Assets/images/google.png')} />
                                                </span>{" "} */}
                                                {/* Google */}
                                            </a>
                                            {/* <GoogleLogin
                                                onSuccess={responseMessage}
                                                onError={errorMessage}
                                            /> */}
                                            <GoogleLoginComponent data={iState} callBack={updateState} style={{ width: '50%' }} />
                                        </li>
                                        <li>
                                            <a >
                                                {" "}
                                                {/* <span>
                                                    <img src={require('../../Assets/images/facebook.png')} />
                                                </span>{" "} */}
                                                <FacebookLoginButton
                                                    data={iState}
                                                    callBack={updateState}
                                                    cssClass="" //my-facebook-button-class
                                                //textButton=""
                                                />
                                                {/* <a>
                                                    <span>
                                                        <img src={require('../../Assets/images/facebook.png')} />
                                                    </span>
                                                    <FacebookLoginButton />
                                                </a> */}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Otp  */}
            <Modal
                show={otpModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category ">
                        <a className="CloseModal "
                            onClick={handleClose}
                        >
                            ×
                        </a>
                        <h3>Verification Code</h3>
                        <div className="ModalLoginArea ModalFormArea">
                            <div className="VerificationCodeArea">
                                <h2>
                                    <span>Please Enter 4-digit OTP</span>
                                </h2>
                                <h6>{`We have sent a verification code to ${phoneNumber}`} </h6>
                                <div className="form-group">
                                    <div className="OTPBox">
                                        <OTPInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderSeparator={<span> </span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                    <p className="text-right">
                                        Didn't receive the code? <a onClick={handleResend}>Resend</a>
                                        <span>{localOtp}</span>
                                    </p>
                                </div>
                            </div>
                            <div className="Buttons">
                                <button
                                    type="button"
                                    className="Button ml-0"
                                    onClick={handleVerifyOtp}
                                >
                                    Verify OTP
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            {/* user detail */}
            <Modal
                show={userModal}//
                className="ModalBox">
                <Modal.Body>
                    <div className="Category ModalLoginArea">
                        <a className="CloseModal" onClick={handleClose}>
                            ×
                        </a>
                        <h3>Create Account</h3>
                        <div className="ModalFormArea">
                            <div className="form-group">
                                <label>Full Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Full Name"
                                    name='name'
                                    value={name}
                                    onChange={handleChange}
                                />
                                {
                                    name ? "" : <span style={{ color: "red" }}>* Required</span>
                                }

                            </div>
                            <div className="form-group">
                                <label>Email ID</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Email ID"
                                    name='email'
                                    value={email}
                                    onChange={handleChange}
                                />
                                <span className='text-danger'>{errors?.email}</span>
                            </div>
                            <div className="Buttons">
                                <button
                                    type="button"
                                    className="Button ml-0"
                                    onClick={handleUserRegistration}
                                >
                                    Proceed
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            {/* Sucess */}
            <Modal
                show={sucessModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category">
                        <div className="LogOutModalArea">
                            <span>
                                <i className="fa-solid fa-thumbs-up" />
                            </span>
                            <h5>Your Registration is Successful</h5>
                            <div className="Buttons">
                                <a
                                    onClick={handleClose}
                                    className="Button w-100 d-inline-block"
                                >
                                    Done
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* PhoneNumber Modal for Google Login */}
            <Modal
                show={phoneNumberModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category ModalLoginArea">
                        <a className="CloseModal"
                            onClick={handleClose}
                        >
                            ×
                        </a>
                        <h3>Login/Sign Up</h3>
                        <div className="ModalFormArea">
                            <div className="form-group">
                                <label>Mobile Number</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    placeholder="Enter Mobile Number"
                                    name='phoneNumber'
                                    value={phoneNumber}
                                    onChange={handleChange}
                                />
                                <span style={{ color: 'red' }}>{errors?.phoneNumber}</span>
                            </div>
                            <div className="form-group">
                                <label className="CheckBox">
                                    I agree to Terms &amp; Conditions
                                    <input type="checkbox" name='acceptTermsAndCondition' checked={acceptTermsAndCondition} onChange={handleChange} />
                                    <span className="checkmark" />
                                </label> <br />
                                <span style={{ color: 'red', marginLeft: '30px' }}>{errors?.acceptTermsAndCondition}</span>
                            </div>
                            <div className="Buttons">
                                <button
                                    type="button"
                                    className="Button FolderPermissionId ml-0"
                                    onClick={handleLoginWithMobileNumber}
                                >
                                    Log In
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Header