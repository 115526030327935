import React, { useState } from 'react'
import { signInWithGooglePopup } from '../../Utils/Firebase';
import { useDispatch } from 'react-redux';
import { userRegistrationByEmail } from '../../Redux/Slices/Login/Login';
import { toast } from 'react-toastify';
import { GoogleLogin, googleLogout } from '@react-oauth/google';
import isLoggedIn from '../../Utils/IsLoggedIn';
import { jwtDecode } from "jwt-decode";

const GoogleLoginComponent = (props) => {
    const dispatch = useDispatch()
    const handleSuccess = (data) => {
        console.log('success data', data)
        const decode = jwtDecode(data?.credential)
        try {
            const data = { email: decode?.email };
            // closing login modal
            dispatch(userRegistrationByEmail(data))
                .then((res) => {
                    console.log('Response of sign up by email', res)
                    if (!res?.payload?.data?.isUserExist) {
                        props.callBack((prev) => {
                            return {
                                ...prev,
                                loginModal: false,
                                phoneNumberModal: true,
                                email: decode?.email
                            }
                        })
                    } else {
                        props.callBack((prev) => {
                            return {
                                ...prev,
                                loginModal: false,
                                phoneNumberModal: false,
                                email: "",
                            }
                        })
                        window.localStorage.setItem('astroTechWeb', res?.payload?.data?.user?.jwtToken)
                    }
                })
                .catch((error) => {
                    console.error('Registration error:', error);
                    toast.error('An error occurred during registration. Please try again.');
                });
        } catch (error) {
            console.error('Google sign-in error:', error);
            if (error.code === 'auth/popup-closed-by-user') {
                toast.info('Login popup closed.');
            } else if (error.code === 'auth/network-request-failed') {
                toast.error('Network error. Please check your connection.');
            } else {
                toast.error('An error occurred during Google login. Please try again.');
            }
        }


    }

    return (
        <div>
            <GoogleLogin
                onSuccess={(credentialResponse) => {
                    handleSuccess(credentialResponse);
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
            />
        </div>
    )
}

export default GoogleLoginComponent

