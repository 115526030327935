import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FamilyMemberListApiSlice } from '../../Redux/Slices/Profile/AddFamilyMember'
import isLoggedIn from '../../Utils/IsLoggedIn'

const initialState = {
    fullName: "",
    gender: "",
    dob: "",
    timeOfBirth: "",
    time: "",
    placeOfBirth: ""
}
const Kundi = () => {
    const [iState, updateState] = useState(initialState)
    const { fullName, gender, dob, timeOfBirth, time, placeOfBirth } = iState
    const userData = useSelector((state) => state?.userData?.userData)
    const familyMemberList = useSelector((state) => state?.familyMemberList?.familyMemberList)
    console.log('family member list', familyMemberList)
    const dispatch = useDispatch()
    console.log('user data', userData)

    console.log('iState', iState)

    useEffect(() => {
        if (userData?.data?.name) {
            updateState({
                ...iState,
                fullName: userData?.data?.name,
                gender: userData?.data?.gender,
                dob: userData?.data?.dob,
                timeOfBirth: userData?.data?.birthTime,
                placeOfBirth: userData?.data?.birthPlace
            })
        } else {
            console.log('iState in free kundli', iState)
            updateState(initialState)
        }

    }, [userData?.data?.name])

    useEffect(() => {
        dispatch(FamilyMemberListApiSlice())
    }, [])
    return (
        <div>
            <div
            //className="tab-pane fade show active"
            //id="kundli"
            >
                <div className="row">
                    <div className="col-lg-6">
                        <div className="NewKundliArea NewKundiBoxHeight">
                            <h2>New Kundli</h2>
                            <div className="NewKundliBox NewKundiBoxHeight2">
                                <div className="CommonForm">
                                    <div className="form-group">
                                        <label>Full Name</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Your Name"
                                            value={fullName}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Gender</label>
                                        <select className="form-control"
                                            value={gender}
                                        >
                                            <option>Select gender</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                        </select>
                                    </div>
                                    <h5>Birth Details</h5>
                                    <div className="form-group">
                                        <label>Date</label>
                                        <input type="date" className="form-control"
                                            value={dob}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Time</label>
                                        <input type="time" className="form-control"
                                            value={timeOfBirth}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Birth Place</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Enter Birth Place"
                                            value={placeOfBirth}
                                        />
                                    </div>
                                    <div className="Buttons">
                                        <button
                                            type="button"
                                            className="Button"
                                            id="GenerateHoroscope"
                                        >
                                            Generate Kundli{" "}
                                            <i className="fa-solid fa-chevron-right" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="SaveKundliArea NewKundiBoxHeight">
                            {/* <h2>Saved Member</h2> */}
                            <div className="NewKundliBox NewKundiBoxHeight2">
                                {
                                    isLoggedIn() ? familyMemberList?.data?.length > 0 ?
                                        familyMemberList?.data?.map((item) => {
                                            console.log('item', item)
                                            return (<div className="SaveKundliBox">
                                                <span className="Overlay">{item?.name?.slice(0, 1)?.toUpperCase()}</span>
                                                <ul>
                                                    <li>{item?.name}</li>

                                                </ul>
                                                <p>{item?.dob} {item?.birthTime}</p>

                                            </div>)
                                        }) : "No Data available" : <div >
                                        <div className="SaveKundliArea">
                                            {/* <h2>Saved Member</h2> */}
                                            <div className="NewKundliBox">
                                                <div className="LoginNowArea">
                                                    <figure>
                                                        <img src={require('../../Assets/images/profile-1.png')} />
                                                    </figure>
                                                    <h3>
                                                        Please login to get <br />
                                                        free kundli of <br />
                                                        existing profiles
                                                    </h3>
                                                    <div className="Buttons">
                                                        <a
                                                            href="javscript:void(0);"
                                                            className="LoginNow Button"
                                                            id="login-panchang"
                                                        >
                                                            Login Now <i className="fa-solid fa-chevron-right" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="BoxContent2 mt-5">
                            <p>
                                Most of the Numerology Predictions are based on your ruling
                                number and it plays a key role in deciding your destiny.
                                Your ruling number helps you decide what's best for you.
                                Make career choices based on your ruling number.
                            </p>
                            <p>
                                This will surely help you overcome obstacles on the way and
                                achieve success in life. Numerology can also help you find
                                the perfect match for you. You can get accurate numerology
                                readings by finding out what your ruling number is. Your
                                ruling number is calculated by adding the numbers of your
                                birth date. The numbers are added till a single digit is
                                obtained, which becomes the numerology or ruling number.
                                Your numerology number consists of the blueprint to your
                                life, and the various aspects in it.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Kundi