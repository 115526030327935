import React from 'react'

const BookingInfo = () => {
    return (
        <div><>
            <div className="StepBox">
                <ul>
                    <li className="Current">
                        <span className="CircleImg">
                            <img src={require('../../../Assets/images/endUserImages/first-img.png')} />
                        </span>
                        <span className="Text">Booking info</span>
                    </li>
                    <li>
                        <span className="Circle Default" />
                        <span className="Text">Calendar Availability</span>
                    </li>
                    <li>
                        <span className="Circle Default" />
                        <span className="Text">Basic Details</span>
                    </li>
                    <li>
                        <span className="Circle Default" />
                        <span className="Text">Payment</span>
                    </li>
                </ul>
            </div>
            <div className="SmallWrapperBox">
                <div className="ConsultantService">
                    <div className="Heading">
                        <h3>Marriage Consultation Service</h3>
                        <p>
                            Welcome to our Marrige Consultation service. Our experienced
                            astrologers are here to assist you in understanding your martial
                            prospects
                        </p>
                    </div>
                    <div className="ServiceDetailsEnd">
                        <div className="SdLeft">
                            <h5>Service Details:</h5>
                            <ul>
                                <li>
                                    <i className="fa-solid fa-check" />
                                    Insights into your future marriage
                                </li>
                                <li>
                                    <i className="fa-solid fa-check" />
                                    Guidance on compability
                                </li>
                                <li>
                                    <i className="fa-solid fa-check" />
                                    Analysis of astrological aspects related to marriage
                                </li>
                            </ul>
                        </div>
                        <div className="SdRight">
                            <ul>
                                <li>
                                    <a href="javascript:void(0);">15 Mins</a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);" className="active">
                                        30 Mins
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0);">45 Mins</a>
                                </li>
                            </ul>
                            <h6>Pricing:</h6>
                            <h1>
                                Rs. 1500<span>/min</span>
                            </h1>
                        </div>
                    </div>
                    <div className="ChooseOptionAreaEnd">
                        <h4>
                            Choose an option to proceed:{" "}
                            <span>
                                <img src={require('../../../Assets/images/endUserImages/info-circle.png')} />
                            </span>
                        </h4>
                        <article>
                            <label className="Radio">
                                <input type="radio" name="Excellent" />
                                <span className="checkmark" />
                            </label>
                            <aside>
                                <div>
                                    <h6>Matching a Marriage Profile</h6>
                                    <p>A brief description of what this service entails.</p>
                                </div>
                                <span className="RadioSpan" />
                                <figure>
                                    <img src={require('../../../Assets/images/endUserImages/img-1.png')} />

                                </figure>
                            </aside>
                        </article>
                        <article>
                            <label className="Radio">
                                <input type="radio" name="Excellent" />
                                <span className="checkmark" />
                            </label>
                            <aside>
                                <div>
                                    <h6>Know Your Marriage Profile</h6>
                                    <p>A brief description of this option.</p>
                                </div>
                                <figure>
                                    <img src={require('../../../Assets/images/endUserImages/img-2.png')} />
                                </figure>
                            </aside>
                        </article>
                    </div>
                    <div className="col-lg-6 mx-auto">
                        <div className="Buttons">
                            <a href="calendar-availability.html" className="ContinueBtn">
                                Continue
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </>
        </div>
    )
}

export default BookingInfo