import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { bannerApiSlice } from '../../Redux/Slices/Banner/banner';
const Hero = () => {

    const dispatch = useDispatch()

    const { bannerData } = useSelector((state) => state?.bannerData)
    console.log('banner data', bannerData)

    const options = {
        items: 4,
        nav: true,
        rewind: true,
        autoplay: true,
        navText: [
            '<span class="custom-prev-arrow">&#8249;</span>', // Left arrow HTML entity
            '<span class="custom-next-arrow">&#8250;</span>'  // Right arrow HTML entity
        ]
    };

    const events = {
        // onDragged: function(event) {...},
        // onChanged: function(event) {...}
    };


    useEffect(() => {
        dispatch(bannerApiSlice())
    }, [])
    return (
        <div>
            <div id="hero">
                <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <ol className="carousel-indicators">
                        <li
                            data-target="#carouselExampleIndicators"
                            data-slide-to={0}
                            className="active"
                        />
                        <li data-target="#carouselExampleIndicators" data-slide-to={1} />
                        <li data-target="#carouselExampleIndicators" data-slide-to={2} />
                    </ol>
                    <div className="carousel-inner">
                        <div className="carousel-item active" data-interval="2000">
                            <div className="carousel-container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className="animate__animated animate__fadeInDown">
                                                {/* Discover Your Perfect Match and Merital Insights */}
                                                Discover your perfect match and marital<br /> insights
                                            </h2>
                                            <ul>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/accurate.png')} />
                                                    </span>
                                                    Expert Kundli Analysis
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/prediction.png')} />

                                                    </span>
                                                    Marital Insights
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/free.png')} />
                                                    </span>
                                                    Compatibility Assessment
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/simple.png')} />
                                                    </span>
                                                    Personalized Remedies
                                                </li>
                                                {/* <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/expert.png')} />

                                                    </span>
                                                    Designed by Expert and Experienced Astrologers
                                                </li> */}
                                            </ul>
                                            <a
                                                className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                            >
                                                Consult Now  "Marriage Compatibility" Service <i className="fa-solid fa-chevron-right" />
                                            </a>
                                        </div>
                                        <div className="col-lg-6">
                                            <figure>
                                                {/* <img src={require('../../Assets/images/banner-2.png')} /> */}
                                                <img src={bannerData?.data?.result?.[0]?.image} width={450} height={340} style={{ borderRadius: '10%' }} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-interval="2000">
                            <div className="carousel-container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className="animate__animated animate__fadeInDown">
                                                Unlock stability in your professional journey and career path
                                            </h2>
                                            <ul>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/accurate.png')} />
                                                    </span>
                                                    Career Guidance
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/prediction.png')} />
                                                    </span>
                                                    Overcome Instability
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/free.png')} />
                                                    </span>
                                                    Planetary Influences.
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/expert.png')} />
                                                    </span>
                                                    Professional Harmony
                                                </li>

                                            </ul>
                                            <a

                                                className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                            >
                                                Consult Now  "Career Success Guidance" Service <i className="fa-solid fa-chevron-right" />
                                            </a>
                                        </div>
                                        <div className="col-lg-6">
                                            <figure>
                                                {/* <img src={require('../../Assets/images/banner-2.png')} /> */}
                                                <img src={bannerData?.data?.result?.[1]?.image} width={450} height={340} style={{ borderRadius: '10%' }} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item" data-interval="2000">
                            <div className="carousel-container">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <h2 className="animate__animated animate__fadeInDown">
                                                Overcome Challenges & Find Your Ideal Business Path
                                            </h2>
                                            <ul>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/accurate.png')} />
                                                    </span>
                                                    Overcome Challenges
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/prediction.png')} />
                                                    </span>
                                                    Business Name Suggestion
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/free.png')} />
                                                    </span>
                                                    Favorable Muhurthas.
                                                </li>
                                                <li>
                                                    <span>
                                                        <img src={require('../../Assets/images/expert.png')} />
                                                    </span>
                                                    Planetary Influences
                                                </li>
                                            </ul>
                                            <a

                                                className="btn-get-started animate__animated animate__fadeInUp scrollto"
                                            >
                                                Consult Now  "Business Astrology" Service <i className="fa-solid fa-chevron-right" />
                                            </a>
                                        </div>
                                        <div className="col-lg-6" >
                                            <figure >
                                                {/* <img src={require('../../Assets/images/banner-2.png')} /> */}
                                                <img src={bannerData?.data?.result?.[2]?.image} width={450} height={340} style={{ borderRadius: '10%' }} />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero