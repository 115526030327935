import React from 'react'
import Horoscope from './HoroScope/Horoscope'

const Horoscopes = () => {
    return (
        <div
        //className='tab-content'
        >
            <div className="tab-pane" id="Horoscopes">
                <div className="container">
                    <div className="FreeKundliHighlights">
                        <h1>Benefits of Horoscope</h1>
                        <ul>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-1.png')} />
                                </span>
                                Future Predictions
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-2.png')} />

                                </span>
                                Relationship Compatibility
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-3.png')} />

                                </span>
                                Health and Wellness Insights
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-4.png')} />
                                </span>
                                Understand Planetary Positions
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-5.png')} />
                                </span>
                                Cultural and Spiritual Connection
                            </li>
                        </ul>
                        <figure>
                            <img src={require('../../Assets/images/menimg.png')} />
                        </figure>
                    </div>
                </div>
                <section>
                    <div className="GetDetailedchart">
                        <div className="container">
                            <div className="BoxContent2">
                                <h1>Significance Of Horoscope</h1>
                                <p>
                                    A well-crafted horoscope can guide individuals in making important
                                    life decisions by providing foresight into the potential
                                    consequences of their actions. It can be particularly helpful in
                                    matters such as marriage, purchasing a new property, starting a
                                    business, buying a vehicle, naming a newborn, and more. Therefore,
                                    it is crucial to consult only the best astrologers for accurate and
                                    insightful advice.
                                </p>
                                <p>
                                    The Horoscope can give you insights into different time spans like a
                                    day, week, month, or even a year.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="AllHoroscopes">
                            <ul>
                                <li>
                                    <a >
                                        <figure>

                                            <img src={require('../../Assets/images/horoscope-01.png')} />
                                        </figure>
                                        <figcaption>Aries</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-2.png')} />
                                        </figure>
                                        <figcaption>Taurus</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-3.png')} />
                                        </figure>
                                        <figcaption>Gemini</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-4.png')} />

                                        </figure>
                                        <figcaption>Cancer</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-5.png')} />

                                        </figure>
                                        <figcaption>Leo</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-6.png')} />

                                        </figure>
                                        <figcaption>Virgo</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-7.png')} />

                                        </figure>
                                        <figcaption>Libra</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-8.png')} />

                                        </figure>
                                        <figcaption>Scorpio</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-9.png')} />

                                        </figure>
                                        <figcaption>Sagittarus</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-10.png')} />

                                        </figure>
                                        <figcaption>Capricorn</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-11.png')} />

                                        </figure>
                                        <figcaption>Aquarius</figcaption>
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <figure>
                                            <img src={require('../../Assets/images/horoscope-12.png')} />
                                        </figure>
                                        <figcaption>Pisces</figcaption>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="AllHoroscopesTabs">
                            <ul className="nav nav-tabs FourTabs">
                                <li className="nav-item">
                                    <a
                                        href="#DailyHoroscope"
                                        data-toggle="tab"
                                        className="nav-link active"
                                    >
                                        Daily Horoscope
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#WeeklyHoroscope" data-toggle="tab" className="nav-link ">
                                        Weekly Horoscope
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#WeeklyHoroscope" data-toggle="tab" className="nav-link">
                                        Monthly Horoscope
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#WeeklyHoroscope" data-toggle="tab" className="nav-link">
                                        Yearly Horoscope
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div className="tab-pane fade active show" id="DailyHoroscope">
                                    <div className="OppotuniesTabs">
                                        <ul className="nav nav-pills nav-fill">
                                            <li className="nav-item">
                                                <a href="#Love" data-toggle="tab" className="nav-link active">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/love.png')} />
                                                    </span>{" "}
                                                    Love
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Health" data-toggle="tab" className="nav-link ">
                                                    {" "}
                                                    <span>

                                                        <img src={require('../../Assets/images/health.png')} />
                                                    </span>{" "}
                                                    Health
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Career" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/career.png')} />

                                                    </span>{" "}
                                                    Career
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Emotions" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/emotions.png')} />

                                                    </span>{" "}
                                                    Emotions
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Travels" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/travels.png')} />

                                                    </span>{" "}
                                                    Travels
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="Love">
                                            <div className="CommonResultBox LoveBorder">
                                                <h5>Love</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Health">
                                            <div className="CommonResultBox HealthBorder">
                                                <h5>Health</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Career">
                                            <div className="CommonResultBox CareerBorder">
                                                <h5>Career</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Emotions">
                                            <div className="CommonResultBox EmotionsBorder">
                                                <h5>Emotions</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Travels">
                                            <div className="CommonResultBox TravelsBorder">
                                                <h5>Travels</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today's Horoscope</h1>
                                            <p>
                                                Hey Aries (March 21 - April 19)! I hope you have already gone
                                                through the Aries forecast today. Now learn more about their
                                                way of living life ahead.
                                            </p>
                                            <p>
                                                Aries daily horoscope, also known as Aries daily forecast—the
                                                most energetic zodiac sign out of the twelve—has its natives
                                                born as runners. Their brains and bodies run like aggressive
                                                horses. They can’t function if they don't get to move
                                                constantly.
                                            </p>
                                            <p>
                                                This is an interesting zodiac sign with many attributes to
                                                watch out for. The “Aries” daily horoscope reveals its chances
                                                of luck, love, marriage, career, health, etc., helping Aries
                                                decide what is best for them.
                                            </p>
                                            <p>
                                                It’s in their blood to take up challenges, and therefore, they
                                                must be alert about the risks and dangers involved in their
                                                life journey. Aries have a winning quality in them, but to
                                                maintain being a winner, they must have an idea of their
                                                future.
                                            </p>
                                            <p>
                                                The natives, both male and female, are usually extra charged
                                                up and are highly optimistic. Their life is all about being
                                                physically active. They are natural athletes who are immensely
                                                inclined towards competitions.
                                            </p>
                                            <p>
                                                Aries's frankness and on-the-face attitude are often
                                                misconceived as rude, but their intentions are always right.
                                                They signify the qualities of Lord Mars in the natives, i.e.,
                                                positive aggression, piousness, and movement.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Likes and Dislikes</h1>
                                            <p>
                                                Let us read ahead to know more about such interesting
                                                personality traits associated with them in terms of likes and
                                                dislikes. If you are an Aries born and read the Aries zodiac
                                                sign today, you will save yourself a lot of time and not
                                                overlook your personality.
                                            </p>
                                            <div className="LikeDisLike">
                                                <h6>Likes</h6>
                                                <ul>
                                                    <li>1- Enjoys travel and adventure sports.</li>
                                                    <li>
                                                        2- Good at debates and wishes the last one to end a
                                                        conversation.
                                                    </li>
                                                    <li>
                                                        3- Keeps updates about new innovations and wishes to learn
                                                        a new skill every now and then.
                                                    </li>
                                                    <li>4- Likes to be their own boss.</li>
                                                </ul>
                                            </div>
                                            <div className="LikeDisLike">
                                                <h6>Dislikes</h6>
                                                <ul>
                                                    <li>1- Do not like restrictions.</li>
                                                    <li>2- Hates being criticised.</li>
                                                    <li>3- Can’t tolerate disrespect from anyone.</li>
                                                    <li>
                                                        4- Can’t work under someone for a long period of time.
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                                Now that we have a fair idea of how Aries behave and what are
                                                their prominent attributes, we must read about the workings of
                                                different Aries today's horoscope and know about the Aries
                                                star sign daily.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today Love Horoscope</h1>
                                            <p className="mb-0">
                                                Aries have a quick way of approaching partners in love. They
                                                like quick results, and hence they prefer to be on the chasing
                                                side. It will be interesting to read how well they manage
                                                their love life as they take it forward.
                                            </p>
                                            <p className="mb-0">
                                                Hence, Aries love horoscope today helps analyse how they act
                                                up in a relationship with their partners. Their balance with
                                                their lovers and wives will be put to the test in different
                                                situations, and today’s Aries will predict that for them every
                                                day.
                                            </p>
                                            <p>
                                                They need Aries daily love horoscope, especially when they are
                                                planning a date or a wedding anniversary party. It helps them
                                                to be ready in advance and avoid mistakes. Go to the column of
                                                Love for today's day for Aries, or the aries star sign today
                                                love, on such occasions, and rest assured you will get your
                                                answers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today Health Horoscope</h1>
                                            <p>
                                                Aries are physically active beings, so much so that they tend
                                                to exhaust and overstress themselves. While their health
                                                issues are minimal as most of them adapt to any form of
                                                exercise, as they are hard workers and punctual.
                                            </p>
                                            <p>
                                                However, their mental peace is often affected if they do not
                                                accomplish what they desire. Also, major illnesses, if any,
                                                are observed around the stomach, kidneys and head, such as
                                                gastric diseases, migraine and kidney infections.
                                            </p>
                                            <p>
                                                Hence, they may take Aries health horoscope today from the
                                                daily aries horoscope to help them know about their health
                                                status probabilities in advance. It predicts their day
                                                healthwise. It is a necessary thing as they are constantly
                                                moving. In order to be active, they need to be healthy.
                                            </p>
                                            <p>
                                                In the absence of good health, their body and mind cannot
                                                function properly. Through today's Aries horoscope, the native
                                                can regulate their health standards and take precautions in
                                                advance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="WeeklyHoroscope">
                                    <div className="OppotuniesTabs">
                                        <ul className="nav nav-pills nav-fill">
                                            <li className="nav-item">
                                                <a href="#Love" data-toggle="tab" className="nav-link active">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/love.png')} />
                                                    </span>{" "}
                                                    Love
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Health" data-toggle="tab" className="nav-link ">
                                                    {" "}
                                                    <span>

                                                        <img src={require('../../Assets/images/health.png')} />
                                                    </span>{" "}
                                                    Health
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Career" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/career.png')} />

                                                    </span>{" "}
                                                    Career
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Emotions" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/emotions.png')} />

                                                    </span>{" "}
                                                    Emotions
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Travels" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/travels.png')} />

                                                    </span>{" "}
                                                    Travels
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="Love">
                                            <div className="CommonResultBox LoveBorder">
                                                <h5>Love</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Health">
                                            <div className="CommonResultBox HealthBorder">
                                                <h5>Health</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Career">
                                            <div className="CommonResultBox CareerBorder">
                                                <h5>Career</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Emotions">
                                            <div className="CommonResultBox EmotionsBorder">
                                                <h5>Emotions</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Travels">
                                            <div className="CommonResultBox TravelsBorder">
                                                <h5>Travels</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today's Horoscope</h1>
                                            <p>
                                                Hey Aries (March 21 - April 19)! I hope you have already gone
                                                through the Aries forecast today. Now learn more about their
                                                way of living life ahead.
                                            </p>
                                            <p>
                                                Aries daily horoscope, also known as Aries daily forecast—the
                                                most energetic zodiac sign out of the twelve—has its natives
                                                born as runners. Their brains and bodies run like aggressive
                                                horses. They can’t function if they don't get to move
                                                constantly.
                                            </p>
                                            <p>
                                                This is an interesting zodiac sign with many attributes to
                                                watch out for. The “Aries” daily horoscope reveals its chances
                                                of luck, love, marriage, career, health, etc., helping Aries
                                                decide what is best for them.
                                            </p>
                                            <p>
                                                It’s in their blood to take up challenges, and therefore, they
                                                must be alert about the risks and dangers involved in their
                                                life journey. Aries have a winning quality in them, but to
                                                maintain being a winner, they must have an idea of their
                                                future.
                                            </p>
                                            <p>
                                                The natives, both male and female, are usually extra charged
                                                up and are highly optimistic. Their life is all about being
                                                physically active. They are natural athletes who are immensely
                                                inclined towards competitions.
                                            </p>
                                            <p>
                                                Aries's frankness and on-the-face attitude are often
                                                misconceived as rude, but their intentions are always right.
                                                They signify the qualities of Lord Mars in the natives, i.e.,
                                                positive aggression, piousness, and movement.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Likes and Dislikes</h1>
                                            <p>
                                                Let us read ahead to know more about such interesting
                                                personality traits associated with them in terms of likes and
                                                dislikes. If you are an Aries born and read the Aries zodiac
                                                sign today, you will save yourself a lot of time and not
                                                overlook your personality.
                                            </p>
                                            <div className="LikeDisLike">
                                                <h6>Likes</h6>
                                                <ul>
                                                    <li>1- Enjoys travel and adventure sports.</li>
                                                    <li>
                                                        2- Good at debates and wishes the last one to end a
                                                        conversation.
                                                    </li>
                                                    <li>
                                                        3- Keeps updates about new innovations and wishes to learn
                                                        a new skill every now and then.
                                                    </li>
                                                    <li>4- Likes to be their own boss.</li>
                                                </ul>
                                            </div>
                                            <div className="LikeDisLike">
                                                <h6>Dislikes</h6>
                                                <ul>
                                                    <li>1- Do not like restrictions.</li>
                                                    <li>2- Hates being criticised.</li>
                                                    <li>3- Can’t tolerate disrespect from anyone.</li>
                                                    <li>
                                                        4- Can’t work under someone for a long period of time.
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                                Now that we have a fair idea of how Aries behave and what are
                                                their prominent attributes, we must read about the workings of
                                                different Aries today's horoscope and know about the Aries
                                                star sign daily.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today Love Horoscope</h1>
                                            <p className="mb-0">
                                                Aries have a quick way of approaching partners in love. They
                                                like quick results, and hence they prefer to be on the chasing
                                                side. It will be interesting to read how well they manage
                                                their love life as they take it forward.
                                            </p>
                                            <p className="mb-0">
                                                Hence, Aries love horoscope today helps analyse how they act
                                                up in a relationship with their partners. Their balance with
                                                their lovers and wives will be put to the test in different
                                                situations, and today’s Aries will predict that for them every
                                                day.
                                            </p>
                                            <p>
                                                They need Aries daily love horoscope, especially when they are
                                                planning a date or a wedding anniversary party. It helps them
                                                to be ready in advance and avoid mistakes. Go to the column of
                                                Love for today's day for Aries, or the aries star sign today
                                                love, on such occasions, and rest assured you will get your
                                                answers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today Health Horoscope</h1>
                                            <p>
                                                Aries are physically active beings, so much so that they tend
                                                to exhaust and overstress themselves. While their health
                                                issues are minimal as most of them adapt to any form of
                                                exercise, as they are hard workers and punctual.
                                            </p>
                                            <p>
                                                However, their mental peace is often affected if they do not
                                                accomplish what they desire. Also, major illnesses, if any,
                                                are observed around the stomach, kidneys and head, such as
                                                gastric diseases, migraine and kidney infections.
                                            </p>
                                            <p>
                                                Hence, they may take Aries health horoscope today from the
                                                daily aries horoscope to help them know about their health
                                                status probabilities in advance. It predicts their day
                                                healthwise. It is a necessary thing as they are constantly
                                                moving. In order to be active, they need to be healthy.
                                            </p>
                                            <p>
                                                In the absence of good health, their body and mind cannot
                                                function properly. Through today's Aries horoscope, the native
                                                can regulate their health standards and take precautions in
                                                advance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="WeeklyHoroscope">
                                    <div className="OppotuniesTabs">
                                        <ul className="nav nav-pills nav-fill">
                                            <li className="nav-item">
                                                <a href="#Love" data-toggle="tab" className="nav-link active">
                                                    {" "}
                                                    <span>
                                                        <img src="images/love.png" />
                                                    </span>{" "}
                                                    Love
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Health" data-toggle="tab" className="nav-link ">
                                                    {" "}
                                                    <span>
                                                        <img src="images/health.png" />
                                                    </span>{" "}
                                                    Health
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Career" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src="images/career.png" />
                                                    </span>{" "}
                                                    Career
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Emotions" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src="images/emotions.png" />
                                                    </span>{" "}
                                                    Emotions
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Travels" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src="images/travels.png" />
                                                    </span>{" "}
                                                    Travels
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="Love">
                                            <div className="CommonResultBox LoveBorder">
                                                <h5>Love</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Health">
                                            <div className="CommonResultBox HealthBorder">
                                                <h5>Health</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Career">
                                            <div className="CommonResultBox CareerBorder">
                                                <h5>Career</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Emotions">
                                            <div className="CommonResultBox EmotionsBorder">
                                                <h5>Emotions</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Travels">
                                            <div className="CommonResultBox TravelsBorder">
                                                <h5>Travels</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today's Horoscope</h1>
                                            <p>
                                                Hey Aries (March 21 - April 19)! I hope you have already gone
                                                through the Aries forecast today. Now learn more about their
                                                way of living life ahead.
                                            </p>
                                            <p>
                                                Aries daily horoscope, also known as Aries daily forecast—the
                                                most energetic zodiac sign out of the twelve—has its natives
                                                born as runners. Their brains and bodies run like aggressive
                                                horses. They can’t function if they don't get to move
                                                constantly.
                                            </p>
                                            <p>
                                                This is an interesting zodiac sign with many attributes to
                                                watch out for. The “Aries” daily horoscope reveals its chances
                                                of luck, love, marriage, career, health, etc., helping Aries
                                                decide what is best for them.
                                            </p>
                                            <p>
                                                It’s in their blood to take up challenges, and therefore, they
                                                must be alert about the risks and dangers involved in their
                                                life journey. Aries have a winning quality in them, but to
                                                maintain being a winner, they must have an idea of their
                                                future.
                                            </p>
                                            <p>
                                                The natives, both male and female, are usually extra charged
                                                up and are highly optimistic. Their life is all about being
                                                physically active. They are natural athletes who are immensely
                                                inclined towards competitions.
                                            </p>
                                            <p>
                                                Aries's frankness and on-the-face attitude are often
                                                misconceived as rude, but their intentions are always right.
                                                They signify the qualities of Lord Mars in the natives, i.e.,
                                                positive aggression, piousness, and movement.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Likes and Dislikes</h1>
                                            <p>
                                                Let us read ahead to know more about such interesting
                                                personality traits associated with them in terms of likes and
                                                dislikes. If you are an Aries born and read the Aries zodiac
                                                sign today, you will save yourself a lot of time and not
                                                overlook your personality.
                                            </p>
                                            <div className="LikeDisLike">
                                                <h6>Likes</h6>
                                                <ul>
                                                    <li>1- Enjoys travel and adventure sports.</li>
                                                    <li>
                                                        2- Good at debates and wishes the last one to end a
                                                        conversation.
                                                    </li>
                                                    <li>
                                                        3- Keeps updates about new innovations and wishes to learn
                                                        a new skill every now and then.
                                                    </li>
                                                    <li>4- Likes to be their own boss.</li>
                                                </ul>
                                            </div>
                                            <div className="LikeDisLike">
                                                <h6>Dislikes</h6>
                                                <ul>
                                                    <li>1- Do not like restrictions.</li>
                                                    <li>2- Hates being criticised.</li>
                                                    <li>3- Can’t tolerate disrespect from anyone.</li>
                                                    <li>
                                                        4- Can’t work under someone for a long period of time.
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                                Now that we have a fair idea of how Aries behave and what are
                                                their prominent attributes, we must read about the workings of
                                                different Aries today's horoscope and know about the Aries
                                                star sign daily.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today Love Horoscope</h1>
                                            <p className="mb-0">
                                                Aries have a quick way of approaching partners in love. They
                                                like quick results, and hence they prefer to be on the chasing
                                                side. It will be interesting to read how well they manage
                                                their love life as they take it forward.
                                            </p>
                                            <p className="mb-0">
                                                Hence, Aries love horoscope today helps analyse how they act
                                                up in a relationship with their partners. Their balance with
                                                their lovers and wives will be put to the test in different
                                                situations, and today’s Aries will predict that for them every
                                                day.
                                            </p>
                                            <p>
                                                They need Aries daily love horoscope, especially when they are
                                                planning a date or a wedding anniversary party. It helps them
                                                to be ready in advance and avoid mistakes. Go to the column of
                                                Love for today's day for Aries, or the aries star sign today
                                                love, on such occasions, and rest assured you will get your
                                                answers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today Health Horoscope</h1>
                                            <p>
                                                Aries are physically active beings, so much so that they tend
                                                to exhaust and overstress themselves. While their health
                                                issues are minimal as most of them adapt to any form of
                                                exercise, as they are hard workers and punctual.
                                            </p>
                                            <p>
                                                However, their mental peace is often affected if they do not
                                                accomplish what they desire. Also, major illnesses, if any,
                                                are observed around the stomach, kidneys and head, such as
                                                gastric diseases, migraine and kidney infections.
                                            </p>
                                            <p>
                                                Hence, they may take Aries health horoscope today from the
                                                daily aries horoscope to help them know about their health
                                                status probabilities in advance. It predicts their day
                                                healthwise. It is a necessary thing as they are constantly
                                                moving. In order to be active, they need to be healthy.
                                            </p>
                                            <p>
                                                In the absence of good health, their body and mind cannot
                                                function properly. Through today's Aries horoscope, the native
                                                can regulate their health standards and take precautions in
                                                advance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="WeeklyHoroscope">
                                    <div className="OppotuniesTabs">
                                        <ul className="nav nav-pills nav-fill">
                                            <li className="nav-item">
                                                <a href="#Love" data-toggle="tab" className="nav-link active">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/love.png')} />
                                                    </span>{" "}
                                                    Love
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Health" data-toggle="tab" className="nav-link ">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/health.png')} />

                                                    </span>{" "}
                                                    Health
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Career" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/career.png')} />

                                                    </span>{" "}
                                                    Career
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Emotions" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/emotions.png')} />
                                                    </span>{" "}
                                                    Emotions
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href="#Travels" data-toggle="tab" className="nav-link">
                                                    {" "}
                                                    <span>
                                                        <img src={require('../../Assets/images/travels.png')} />
                                                    </span>{" "}
                                                    Travels
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="Love">
                                            <div className="CommonResultBox LoveBorder">
                                                <h5>Love</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Health">
                                            <div className="CommonResultBox HealthBorder">
                                                <h5>Health</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Career">
                                            <div className="CommonResultBox CareerBorder">
                                                <h5>Career</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Emotions">
                                            <div className="CommonResultBox EmotionsBorder">
                                                <h5>Emotions</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                        <div className="tab-pane fade" id="Travels">
                                            <div className="CommonResultBox TravelsBorder">
                                                <h5>Travels</h5>
                                                <p>
                                                    You might find it challenging to switch off from work mode,
                                                    even during leisure time. While being organized and on top
                                                    of things is commendable, it's also important to relax and
                                                    enjoy the moment. Today, try to let go of your worries and
                                                    responsibilities. Allow yourself to fully embrace and enjoy
                                                    your free time without the burden of work-related thoughts.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today's Horoscope</h1>
                                            <p>
                                                Hey Aries (March 21 - April 19)! I hope you have already gone
                                                through the Aries forecast today. Now learn more about their
                                                way of living life ahead.
                                            </p>
                                            <p>
                                                Aries daily horoscope, also known as Aries daily forecast—the
                                                most energetic zodiac sign out of the twelve—has its natives
                                                born as runners. Their brains and bodies run like aggressive
                                                horses. They can’t function if they don't get to move
                                                constantly.
                                            </p>
                                            <p>
                                                This is an interesting zodiac sign with many attributes to
                                                watch out for. The “Aries” daily horoscope reveals its chances
                                                of luck, love, marriage, career, health, etc., helping Aries
                                                decide what is best for them.
                                            </p>
                                            <p>
                                                It’s in their blood to take up challenges, and therefore, they
                                                must be alert about the risks and dangers involved in their
                                                life journey. Aries have a winning quality in them, but to
                                                maintain being a winner, they must have an idea of their
                                                future.
                                            </p>
                                            <p>
                                                The natives, both male and female, are usually extra charged
                                                up and are highly optimistic. Their life is all about being
                                                physically active. They are natural athletes who are immensely
                                                inclined towards competitions.
                                            </p>
                                            <p>
                                                Aries's frankness and on-the-face attitude are often
                                                misconceived as rude, but their intentions are always right.
                                                They signify the qualities of Lord Mars in the natives, i.e.,
                                                positive aggression, piousness, and movement.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Likes and Dislikes</h1>
                                            <p>
                                                Let us read ahead to know more about such interesting
                                                personality traits associated with them in terms of likes and
                                                dislikes. If you are an Aries born and read the Aries zodiac
                                                sign today, you will save yourself a lot of time and not
                                                overlook your personality.
                                            </p>
                                            <div className="LikeDisLike">
                                                <h6>Likes</h6>
                                                <ul>
                                                    <li>1- Enjoys travel and adventure sports.</li>
                                                    <li>
                                                        2- Good at debates and wishes the last one to end a
                                                        conversation.
                                                    </li>
                                                    <li>
                                                        3- Keeps updates about new innovations and wishes to learn
                                                        a new skill every now and then.
                                                    </li>
                                                    <li>4- Likes to be their own boss.</li>
                                                </ul>
                                            </div>
                                            <div className="LikeDisLike">
                                                <h6>Dislikes</h6>
                                                <ul>
                                                    <li>1- Do not like restrictions.</li>
                                                    <li>2- Hates being criticised.</li>
                                                    <li>3- Can’t tolerate disrespect from anyone.</li>
                                                    <li>
                                                        4- Can’t work under someone for a long period of time.
                                                    </li>
                                                </ul>
                                            </div>
                                            <p>
                                                Now that we have a fair idea of how Aries behave and what are
                                                their prominent attributes, we must read about the workings of
                                                different Aries today's horoscope and know about the Aries
                                                star sign daily.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today Love Horoscope</h1>
                                            <p className="mb-0">
                                                Aries have a quick way of approaching partners in love. They
                                                like quick results, and hence they prefer to be on the chasing
                                                side. It will be interesting to read how well they manage
                                                their love life as they take it forward.
                                            </p>
                                            <p className="mb-0">
                                                Hence, Aries love horoscope today helps analyse how they act
                                                up in a relationship with their partners. Their balance with
                                                their lovers and wives will be put to the test in different
                                                situations, and today’s Aries will predict that for them every
                                                day.
                                            </p>
                                            <p>
                                                They need Aries daily love horoscope, especially when they are
                                                planning a date or a wedding anniversary party. It helps them
                                                to be ready in advance and avoid mistakes. Go to the column of
                                                Love for today's day for Aries, or the aries star sign today
                                                love, on such occasions, and rest assured you will get your
                                                answers.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="BoxContent2">
                                        <div className="container">
                                            <h1>Aries Today Health Horoscope</h1>
                                            <p>
                                                Aries are physically active beings, so much so that they tend
                                                to exhaust and overstress themselves. While their health
                                                issues are minimal as most of them adapt to any form of
                                                exercise, as they are hard workers and punctual.
                                            </p>
                                            <p>
                                                However, their mental peace is often affected if they do not
                                                accomplish what they desire. Also, major illnesses, if any,
                                                are observed around the stomach, kidneys and head, such as
                                                gastric diseases, migraine and kidney infections.
                                            </p>
                                            <p>
                                                Hence, they may take Aries health horoscope today from the
                                                daily aries horoscope to help them know about their health
                                                status probabilities in advance. It predicts their day
                                                healthwise. It is a necessary thing as they are constantly
                                                moving. In order to be active, they need to be healthy.
                                            </p>
                                            <p>
                                                In the absence of good health, their body and mind cannot
                                                function properly. Through today's Aries horoscope, the native
                                                can regulate their health standards and take precautions in
                                                advance.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="ConsultSectionOuter">
                        <div className="container">
                            <div className="ConsultSection">
                                <div className="ConsultLeft">
                                    <h3>Consult to get an In-depth analysis of Your Kundli</h3>
                                    <p>
                                        Call or chat with experienced Vedic Astrologers to get accurate
                                        predictions about your future and <br /> personalized solutions
                                        for your problems.
                                    </p>
                                </div>
                                <div className="ConsultRight">
                                    <a >Match Kundli</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="FaqAera">
                        <div className="container">
                            <div className="section-title">
                                <h1>Frequently asked questions</h1>
                            </div>
                            <div className="StaticArea">
                                <div id="accordion">
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse1"
                                            aria-expanded="false"
                                        >
                                            <h4>Q 1. What is Panchang?</h4>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse in">
                                            <div className="card-body">
                                                <p>
                                                    A Panchang is a traditional Hindu calendar that provides
                                                    detailed information about the days, months, and astronomical
                                                    positions. It includes details about tithis (lunar days),
                                                    nakshatras (constellations), yogas, karanas, and planetary
                                                    positions, helping people choose auspicious times for various
                                                    activities.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse2"
                                        >
                                            <h4>Q 2. How is a Panchang used in daily life?</h4>
                                        </div>
                                        <div id="collapse2" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse3"
                                        >
                                            <h4>Q 3. What is the significance of Tithi in a Panchang?</h4>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse4"
                                        >
                                            <h4>
                                                Q 4. What Happens When We Do Any Work During Inauspicious
                                                Muhurat?
                                            </h4>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse5"
                                        >
                                            <h4>
                                                Q 5. How do planetary positions in a Panchang affect decisions?
                                            </h4>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse6"
                                        >
                                            <h4>Q 6. What is the significance of Tithi in a Panchang?</h4>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit amet
                                                    lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci, sed
                                                    rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <Horoscope /> */}
            </div>
        </div>
    )
}

export default Horoscopes