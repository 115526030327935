import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { faqListApi } from '../../../Redux/Slices/Faq/faq'
import Footer from '../Footer'
import Header from '../../Header/Header'

const Faq = () => {
    const dispatch = useDispatch()
    const faqData = useSelector((state) => state?.faqData?.faqData)
    console.log('faqData', faqData)

    useEffect(() => {
        dispatch(faqListApi())
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <Header />
            <>
                <div className="NewHeader">
                    <div className="container">
                        <div className="BoxContent mb-0">
                            <h1>FAQs</h1>
                            <p>
                                These terms and conditions of Use (hereinafter referred as “Terms of
                                Usage”) describe and govern the User’s use of the content and services
                                offered by Astrotalk Services Private Limited (Formerly Codeyeti
                                Software Solutions Pvt. Ltd.) through www.astrotalk.com (hereinafter
                                referred as “We” “Astrotalk” “us” “our” “astrotalk application”
                                “Website”)
                            </p>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <section>
                        <div className="FaqAera" id="FaqAera">
                            <div className="container">
                                <div className="section-title">
                                    <h1>Frequently asked questions</h1>
                                </div>
                                <div className="StaticArea">
                                    {
                                        faqData?.data?.map((item, i) => {
                                            return (
                                                <div className="panel-group" id="accordion" style={{ marginBottom: '15px' }}>
                                                    <div className="panel">
                                                        <div
                                                            className="panel-heading"
                                                            data-toggle="collapse"
                                                            data-parent="#accordion"
                                                            href={`#collapse${i}`}
                                                        >
                                                            <h4>{item?.faqName}</h4>
                                                        </div>
                                                        <div id={`collapse${i}`} className="panel-collapse collapse in">
                                                            <div className="panel-body">
                                                                <p>
                                                                    {item?.description}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            )
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </>
            <Footer />
        </div>
    )
}

export default Faq