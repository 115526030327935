import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ProgressBar from './ProgressBar'

const SideNav = () => {
    const { pathname } = useLocation()
    console.log('pathname', pathname)
    const navigate = useNavigate()
    return (
        <div>
            <div className="SidenavBarEnd">
                <div className="SidenavHead" onClick={() => navigate('/')}>
                    <img src={require('../../../Assets/images/Logo.png')} />
                </div>
                <div className="SidenavBody">
                    <ul>
                        <li className={pathname === '/profile' ? 'active' : ""}>
                            <Link to="/profile">
                                <span className="Icon">
                                    <span>
                                        <i className="fa fa-user" />
                                    </span>
                                </span>
                                <span className="Text">Profile</span>
                            </Link>
                        </li>
                        {/* <li className={pathname === 'bookingInfo' ? 'active' : ""}>
                            <Link to='/bookingInfo'>
                                <span className="Icon">
                                    <i className="fa-solid fa-book" />
                                </span>
                                <span className="Text">Booking</span>
                            </Link>
                        </li> */}
                        <li className={pathname === '/appointmentManagement' ? 'active' : ""}>
                            <Link to="/appointmentManagement">
                                <span className="Icon">
                                    <span>
                                        <i className="fa fa-font" />
                                    </span>
                                </span>
                                <span className="Text">Appointment</span>
                            </Link>
                        </li>
                        <li className={pathname === '/payment' ? 'active' : ""}>
                            <Link to="/payment">
                                <span className="Icon">
                                    <span>
                                        <i className="fa-solid fa-hand-holding-dollar" />
                                    </span>
                                </span>
                                <span className="Text">Payments</span>
                            </Link>
                        </li>
                        <li className={pathname === '/support' || pathname === '/supportEnqueryDetail' || pathname === '/supportNotes' ? 'active' : ""}>
                            <Link to="/support">
                                <span className="Icon">
                                    <span>
                                        <i className="fa-solid fa-phone" />
                                    </span>
                                </span>
                                <span className="Text">Support</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <ProgressBar />
            </div>

        </div>
    )
}

export default SideNav