import React, { useEffect } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import Kundi from '../Kundi'
import MatchingKundli from '../MatchingKundli'
import { Link, useLocation, useParams } from 'react-router-dom'

const KundliAndMatchMakingTab = () => {
    const { type, type2 } = useParams()
    console.log('type2', type2, 'type', type)
    const location = useLocation();
    const activeKey = location.pathname.includes('matchMaking') ? 'matchMaking' : 'kundli';
    return (
        <>
            <Tab.Container id="left-tabs-example"
                activeKey={type2}
            // defaultActiveKey={type2}
            // activeKey={activeKey}
            >
                <div className="CommonTabs">
                    <div className='container'>
                        <Nav variant="tabs" className="nav nav-tabs">
                            <Nav.Item className="nav-item">
                                <Nav.Link className="nav-link"
                                    as={Link}
                                    eventKey='kundli'
                                    to={type === 'freekundli' ? '/freeServices/freekundli/kundli' : '/freeServices/kundliMatching/kundli'}
                                >
                                    Kundli{" "}
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item className="nav-item">
                                <Nav.Link
                                    className="nav-link"
                                    as={Link}
                                    eventKey='matchMaking'
                                    to={type === 'freekundli' ? '/freeServices/freekundli/matchMaking' : '/freeServices/kundliMatching/matchMaking'}
                                >
                                    {/* <span>
                                                    <img src={require('../../Assets/images/horoscope.png')} />
                                                </span> */}
                                    Matcing Kundli
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                </div>
                <Tab.Content>
                    <Tab.Pane className="tab-pane" eventKey='kundli'>
                        <Kundi />
                    </Tab.Pane>
                    <Tab.Pane className="tab-pane" eventKey="matchMaking">
                        <MatchingKundli />
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </>
    )
}

export default KundliAndMatchMakingTab