import React, { useEffect } from "react";
import "./App.css"

import {
  Route,
  HashRouter,
  BrowserRouter as Router,
  Routes,
  Navigate,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleLogin } from '@react-oauth/google';
import Main from "./Components/Main/Main";
import Profile from "./Components/EndUser/Profile/Profile";
import AppointmentManagement from "./Components/EndUser/Appointment/AppointmentManagement";
import Payment from "./Components/EndUser/Payment/Payment";
import Support from "./Components/EndUser/Support/Support";
import BookingInfo from "./Components/EndUser/Booking/BookingInfo";
import AboutUs from "./Components/Header/AboutUs";
import FreeServices from "./Components/FreeServices/FreeServices";
import Blogs from "./Components/Main/Blogs";
import BlogPage from "./Components/Main/BlogPage";
import BookingFirstPage from "./Components/Booking/BookingFirstPage";
import BookingSecondePage from "./Components/Booking/BookingSecondePage";
import BookingThirdPage from "./Components/Booking/BookingThirdPage";
import BookingForthPage from "./Components/Booking/BookingForthPage";
import BlogDetail from "./Components/Main/BlogDetail";
import SupportEnquiry from "./Components/EndUser/Support/SupportEnquiry";
import SupportEnqueryDetail from "./Components/EndUser/Support/SupportEnqueryDetail";
import SupportNotes from "./Components/EndUser/Support/SupportNotes";
import Copyright from "./Components/Footer/Company/Copyright";
import TermsAndConditions from "./Components/Footer/Company/TermsAndConditions";
import ContactUs from "./Components/Footer/Company/ContactUs";
import Faq from "./Components/Footer/Company/Faq";
import WhyAstrology from "./Components/Footer/Important/WhyAstrology";
function PrivateRoute({ component: Component, ...rest }) {
  const isLogged = localStorage.getItem("");

  return isLogged ? <Component {...rest} /> : <Navigate to="/" />;
}

// element={<PrivateRoute component={PrivateComponent} />
function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/appointmentManagement" element={<AppointmentManagement />} />
          <Route path="/payment" element={<Payment />} />
          <Route path="/support" element={<Support />} />
          <Route path="/bookingInfo" element={<BookingInfo />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/freeServices/:type/:type2" element={<FreeServices />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogPage" element={<BlogPage />} />
          <Route path="/bookingFirstPage" element={<BookingFirstPage />} />
          <Route path="/bookingSecondePage" element={<BookingSecondePage />} />
          <Route path="/bookingThirdPage" element={<BookingThirdPage />} />
          <Route path="/bookingForthPage" element={<BookingForthPage />} />
          <Route path="/blogDetail" element={<BlogDetail />} />
          <Route path="/supportEnquiry" element={<SupportEnquiry />} />
          <Route path="/supportEnqueryDetail" element={<SupportEnqueryDetail />} />
          <Route path="/supportNotes" element={<SupportNotes />} />
          <Route path="/copyright" element={<Copyright />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/whyAstrology" element={<WhyAstrology />} />
        </Routes>
      </HashRouter>
      <ToastContainer />
    </div>
  );
}

export default App;

