import React from 'react'
import { Link } from 'react-router-dom'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux';

const ProgressBar = () => {
    const progressValue = useSelector((state) => state?.progressStatus?.progressData)
    console.log('progress bar')
    return (
        <div className="SidenavFooter">
            <div style={{ width: '50%', marginLeft: "auto", marginRight: "auto" }}>
                <CircularProgressbar value={progressValue?.data ?? 0} text={`${progressValue?.data ?? ""}%`}
                    styles={buildStyles({
                        pathColor: `rgba(255, 144, 0, 1)`,
                        textColor: 'black',
                        trailColor: '#d6d6d6',
                        backgroundColor: '#3e98c7',
                    })}
                />
            </div>
            <br />
            <h5 style={{ marginTop: '15px' }}>
                Complete <br /> Your Profile
            </h5>
            <Link to='/profile'>Complete Now</Link>
        </div>
    )
}

export default ProgressBar