import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const bannerApiSlice = createAsyncThunk(
    "bannerData/getbannerData",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `banners/banner-list`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    bannerData: [],
    status: false
}
const bannerDataSlice = createSlice({
    name: 'bannerData',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(bannerApiSlice.pending, (state) => {
                state.status = true;
            })
            .addCase(bannerApiSlice.fulfilled, (state, action) => {
                state.status = false;
                state.bannerData = action.payload;
            })
            .addCase(bannerApiSlice.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
    }
})

export default bannerDataSlice.reducer;