import React, { useEffect, useState } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import AddFamilyMember from './AddFamilyMember'
import { useDispatch, useSelector } from 'react-redux'
import { cityListApi, countryListApi, stateListApi, updateProfileApiSlice } from '../../../Redux/Slices/Profile/ProfileSlices'
import { userDataApiSlice } from '../../../Redux/Slices/Profile/userData'
import { uploadImage } from '../../../Utils/FileUpload'
import { progressApi } from '../../../Redux/Slices/Profile/ProgressBarSlice'
import Loader from '../../../Utils/Loader'
import { toast } from 'react-toastify'
import Multiselect from 'multiselect-react-dropdown'
import { Modal } from 'react-bootstrap'
import { emailVerification, otpOnMail } from '../../../Redux/Slices/Login/Login'
import OTPInput from 'react-otp-input'
const initialState = {
    fullname: "",
    phoneNumber: "",
    email: "",
    gender: "",
    dob: "",
    timeOfBirth: "",
    languages: [],
    address1: "",
    address2: "",
    country: "",
    state: "",
    city: "",
    pincode: "",
    placeOfBirth: "",
    profileImg: "",
    emailModal: false,
    emailForVerification: "",
    otpModal: false,
}
const Profile = () => {
    const [iState, updateState] = useState(initialState)
    const [otp, setOtp] = useState("")
    const countryList = useSelector((state) => state?.countryList)
    const stateList = useSelector((state) => state?.countryList)
    const cityList = useSelector((state) => state?.countryList)
    console.log('city list', cityList?.cityList)
    console.log('state list', stateList?.stateList?.data)
    console.log('country list', countryList)
    const {
        fullname,
        phoneNumber,
        email,
        gender,
        dob,
        timeOfBirth,
        languages,
        address1,
        address2,
        country,
        state,
        city,
        pincode,
        placeOfBirth,
        profileImg,
        emailModal,
        emailForVerification,
        otpModal
    } = iState

    const dispatch = useDispatch()

    const handleChange = (e) => {
        const { name, value, checked } = e.target
        console.log('name', name, 'value', value, 'checked', checked)
        updateState({
            ...iState,
            [name]: value
        })
    }
    const handleProfileImgUpload = async (e) => {
        const file = e.target.files[0]
        const url = await uploadImage(file)
        updateState({
            ...iState,
            "profileImg": url.location
        })
    }
    const handleUpdateProfile = () => {
        const data = {
            name: fullname,
            email: email,
            gender: gender,
            dob: dob,
            birthTime: timeOfBirth,
            address1,
            address2,
            country,
            state,
            city,
            pincode,
            birthPlace: placeOfBirth,
            image: profileImg,
            language: languages
        }
        dispatch(updateProfileApiSlice(data)).then((res) => {
            console.log('Response of save detail', res)
            if (res?.payload?.status === 200) {
                dispatch(progressApi(res?.payload?.data?._id))
                dispatch(userDataApiSlice())
                updateState({
                    ...iState,
                    email: res?.payload?.data?.email ?? "",
                    fullname: res?.payload?.data?.name ?? "",
                    phoneNumber: res?.payload?.data?.contact ?? "",
                    gender: res?.payload?.data?.gender ?? "",
                    dob: res?.payload?.data?.dob ?? "",
                    timeOfBirth: res?.payload?.data?.birthTime ?? "",
                    address1: res?.payload?.data?.address1 ?? "",
                    address2: res?.payload?.data?.address2 ?? "",
                    country: res?.payload?.data?.country ?? "",
                    state: res?.payload?.data?.state ?? "",
                    city: res?.payload?.data?.city ?? "",
                    placeOfBirth: res?.payload?.data?.birthPlace ?? "",
                    profileImg: res?.payload?.data?.image ?? "",
                    languages: res?.payload?.data?.language ?? "",
                    pincode: res?.payload?.data?.pincode ?? ""
                })
                toast.info('Profile Updated Sucessfully', {
                    position: 'top-center',
                    autoClose: 1000
                })
            }
        })
    }

    const languageOptions = [
        { name: 'English', id: 1 },
        { name: 'Hindi', id: 2 },
        { name: 'Bengali', id: 3 },
        { name: 'Tamil', id: 4 },
        // Add more languages here
    ];

    const handleSelect = (selectedList) => {
        updateState({ ...iState, languages: selectedList });
        // setShow({ ...show, eventLanguages: selectedList });
    };

    useEffect(() => {
        dispatch(userDataApiSlice()).then((res) => {
            console.log('res', res)
            if (res?.payload?.status === 200) {
                dispatch(progressApi(res?.payload?.data?._id))
                updateState({
                    ...iState,
                    email: res?.payload?.data?.email ?? "",
                    fullname: res?.payload?.data?.name ?? "",
                    phoneNumber: res?.payload?.data?.contact ?? "",
                    gender: res?.payload?.data?.gender ?? "",
                    dob: res?.payload?.data?.dob ?? "",
                    timeOfBirth: res?.payload?.data?.birthTime ?? "",
                    address1: res?.payload?.data?.address1 ?? "",
                    address2: res?.payload?.data?.address2 ?? "",
                    country: res?.payload?.data?.country ?? "",
                    state: res?.payload?.data?.state ?? "",
                    city: res?.payload?.data?.city ?? "",
                    placeOfBirth: res?.payload?.data?.birthPlace ?? "",
                    profileImg: res?.payload?.data?.image ?? "",
                    languages: res?.payload?.data?.language ?? "",
                    pincode: res?.payload?.data?.pincode ?? ""
                })
            }
        })
        dispatch(countryListApi())
    }, [])

    useEffect(() => {
        if (country) {
            console.log('country', country)
            dispatch(stateListApi(country))
        }
    }, [country])

    useEffect(() => {
        if (country && state) {
            const data = {
                country,
                state
            }
            console.log('data', data)
            dispatch(cityListApi(data))
        }
    }, [country, state])

    const handleModalOpen = () => {
        updateState({
            ...iState,
            emailModal: true
        })
    }

    const handleEmailVerification = () => {
        const data = {
            email: emailForVerification
        }
        console.log('email data', email)
        dispatch(emailVerification(data)).then((res) => {
            console.log('email verification response', res)
            if (res?.payload?.status === 200) {
                toast.info('Email Verification Success', {
                    position: 'top-center',
                    autoClose: 1000
                })
                updateState({
                    ...iState,
                    emailModal: false,
                    emailForVerification: "",
                    otpModal: true
                })
            } else {
                toast.error('Email Verification Failed', {
                    position: 'top-center',
                    autoClose: 1000
                })
            }
        })
    }


    const handleVerifyOtp = () => {

        // if (otp) {
        //     const data = {
        //         otp
        //     }
        //     dispatch(otpOnMail(data))
        //         .then((res) => {
        //             if (res?.payload?.status === 200) {
        //                 updateState({
        //                     ...iState,
        //                     otpModal: false,

        //                 })
        //                 setOtp("")
        //                 toast.success('Otp Matched Sucessfully', {
        //                     position: 'top-right',
        //                     autoClose: 1000
        //                 })
        //                 // profile api dispatch

        //                 dispatch(userDataApiSlice()).then((res) => {
        //                     console.log('res', res)
        //                     if (res?.payload?.status === 200) {
        //                         dispatch(progressApi(res?.payload?.data?._id))
        //                         updateState({
        //                             ...iState,
        //                             email: res?.payload?.data?.email ?? "",
        //                             fullname: res?.payload?.data?.name ?? "",
        //                             phoneNumber: res?.payload?.data?.contact ?? "",
        //                             gender: res?.payload?.data?.gender ?? "",
        //                             dob: res?.payload?.data?.dob ?? "",
        //                             timeOfBirth: res?.payload?.data?.birthTime ?? "",
        //                             address1: res?.payload?.data?.address1 ?? "",
        //                             address2: res?.payload?.data?.address2 ?? "",
        //                             country: res?.payload?.data?.country ?? "",
        //                             state: res?.payload?.data?.state ?? "",
        //                             city: res?.payload?.data?.city ?? "",
        //                             placeOfBirth: res?.payload?.data?.birthPlace ?? "",
        //                             profileImg: res?.payload?.data?.image ?? "",
        //                             languages: res?.payload?.data?.language ?? "",
        //                             pincode: res?.payload?.data?.pincode ?? "",
        //                             otpModal: false
        //                         })
        //                     }
        //                 })
        //             }
        //         })
        // }

    }
    return (
        <div>
            {/* <Loader /> */}
            <SideNav />
            <Header />
            <div className="WrapperAreaEnd">
                <div className="WrapperBox Customs">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="ProfileArea">
                                <div className="ProfileEditBox">
                                    <figure>
                                        <img src={profileImg} className='Big' />
                                        <span className="Overlay">
                                            <img src={require('../../../Assets/images/endUserImages/edit-icon.png')} />
                                            <input type="file" name='profileImg' onChange={handleProfileImgUpload} />
                                        </span>
                                    </figure>
                                </div>
                                <div className="PrefilledArea Replace">
                                    <form>
                                        <div className="PrefilledMode">
                                            <fieldset disabled="">
                                                <div className="form-group">
                                                    <label>Full Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Full Name"
                                                        value={fullname}
                                                        name='fullname'
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label>Phone Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Phone Number"
                                                        name='phoneNumber'
                                                        value={phoneNumber}
                                                        onChange={handleChange}
                                                        disabled
                                                    />
                                                </div>
                                                <div
                                                    className="form-group position-relative"
                                                //onClick={handleModalOpen}
                                                >
                                                    <label>Email ID</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Email ID"
                                                        name='email'
                                                        value={email}
                                                        // onChange={handleChange}
                                                        readOnly
                                                    />
                                                    {
                                                        false ? <span style={{ color: 'green', position: 'absolute', right: '15px', top: '55%', cursor: 'pointer' }} className='Verified'><i class="fa fa-check" aria-hidden="true"></i></span> : <span style={{ color: 'red', position: 'absolute', right: '15px', top: '55%', cursor: 'pointer' }}><i class="fa fa-xmark" aria-hidden="true"></i></span>
                                                    }


                                                </div>
                                            </fieldset>
                                        </div>
                                        <div className="form-group">
                                            <label>Gender</label>
                                            <ul className="GenderUl">
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Male
                                                        <input type="radio" name="gender" value="male" checked={gender === "male" ? true : false} onChange={handleChange} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                                <li>
                                                    <label className="Radio">
                                                        {" "}
                                                        Female
                                                        <input type="radio" name="gender" value="female" checked={gender === "female" ? true : false} onChange={handleChange} />
                                                        <span className="checkmark" />
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="form-group">
                                            <label>Date of Birth</label>
                                            <input
                                                type="date"
                                                className="form-control"
                                                name='dob'
                                                value={dob}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Place of Birth</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Enter Place of Birth"
                                                name='placeOfBirth'
                                                value={placeOfBirth}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Time of Birth</label>
                                            <input
                                                type="time"
                                                className="form-control"
                                                name='timeOfBirth'
                                                value={timeOfBirth}
                                                onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <label>Language</label>
                                            <Multiselect
                                                options={languageOptions} // Updated options
                                                selectedValues={languages}
                                                onSelect={handleSelect}
                                                onRemove={handleSelect}
                                                displayValue="name" // Display language name
                                                placeholder="--Select--"
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="AddressDetailsBox">
                                <div className="DetailsBoxNew">
                                    <h3>Address</h3>
                                    <div className="ArticleReplace">
                                        <article>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="PrefilledArea">
                                                        <div className="form-group">
                                                            <label>Address Line 1</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Address Line 1"
                                                                name='address1'
                                                                value={address1}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Address Line 2</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Address Line 2"
                                                                name='address2'
                                                                value={address2}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="PrefilledArea">
                                                        <div className="form-group">
                                                            <label>Country</label>
                                                            <select className="form-control" name='country' value={country} onChange={handleChange}>
                                                                <option>--select--</option>
                                                                {
                                                                    countryList?.countryList?.data?.map((item) => {
                                                                        return (<option value={item?.isoCode}>{item?.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>City</label>
                                                            <select className="form-control" name='city' value={city} onChange={handleChange}>
                                                                <option >Select</option>
                                                                {
                                                                    cityList?.cityList?.data?.map((item) => {
                                                                        return (<option value={item?.name}>{item?.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="PrefilledArea">
                                                        <div className="form-group">
                                                            <label>State</label>
                                                            <select className="form-control" name='state' value={state} onChange={handleChange}>
                                                                <option >Select</option>
                                                                {
                                                                    stateList?.stateList?.data?.map((item) => {
                                                                        return (<option value={item?.isoCode}>{item?.name}</option>)
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                        <div className="form-group">
                                                            <label>Pincode</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Enter Pincode"
                                                                name='pincode'
                                                                value={pincode}
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                                <div className="DetailsBoxNew">
                                    <AddFamilyMember />
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-12 mx-auto">
                            <div className="Buttons">
                                <a className="Button SaveDetailsBtn" onClick={handleUpdateProfile}>
                                    Save Details
                                </a>
                                {/* <a href="javascript:void(0);" className="Button UpdateDetailsBtn">
                                    Update Details
                                </a> */}
                            </div>
                        </div>
                    </div>


                    {/* <button className="Button mt-3 UdateDetailsBtn" onClick={handleUpdateProfile}>Update Details</button> */}
                </div>
            </div>
            {/* Email verification */}
            <Modal
                show={emailModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category ModalLoginArea">
                        <a className="CloseModal"
                            onClick={() => updateState({ ...iState, emailModal: false, emailForVerification: "" })}
                        >
                            ×
                        </a>
                        <h3>Enter Your Valid Email</h3>
                        <div className="ModalFormArea">
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Email"
                                    name='emailForVerification'
                                    value={emailForVerification}
                                    onChange={handleChange}
                                />
                                {/* <span style={{ color: 'red' }}>ERROR</span> */}
                            </div>
                            <div className="Buttons">
                                <button
                                    type="button"
                                    className="Button FolderPermissionId ml-0"
                                    onClick={handleEmailVerification}
                                >
                                    Verify
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            {/* Otp */}
            <Modal
                show={otpModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="Category ">
                        <a className="CloseModal "
                            onClick={() => updateState({ ...iState, otpModal: false })}
                        >
                            ×
                        </a>
                        <h3>Verification Code</h3>
                        <div className="ModalLoginArea ModalFormArea">
                            <div className="VerificationCodeArea">
                                <h2>
                                    <span>Please Enter 4-digit OTP</span>
                                </h2>
                                <div className="form-group">
                                    <div className="OTPBox">
                                        <OTPInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={4}
                                            renderSeparator={<span> </span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                    <p className="text-right">
                                        Didn't receive the code? <a
                                        //onClick={handleResend}
                                        >Resend</a>
                                    </p>
                                </div>
                            </div>
                            <div className="Buttons">
                                <button
                                    type="button"
                                    className="Button ml-0"
                                    onClick={handleVerifyOtp}
                                >
                                    Verify OTP
                                </button>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Profile