import React, { useState, useEffect } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { addSupportEnqueryApi, enqueryListApi } from '../../../Redux/Slices/Support/support'
import { uploadImage } from '../../../Utils/FileUpload'
import { userDataApiSlice } from '../../../Redux/Slices/Profile/userData'
import { progressApi } from '../../../Redux/Slices/Profile/ProgressBarSlice'
const initialState = {
    fullName: "",
    email: "",
    contact: "",
    reason: "",
    message: "",
    doc: "",
    error: {}
}
const SupportEnquiry = () => {
    const [iState, updateState] = useState(initialState)
    const { fullName, email, contact, reason, message, doc, error } = iState
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleChange = (event) => {
        updateState({
            ...iState,
            [event.target.name]: event.target.value,
        })
    }

    const handleBack = () => {
        navigate(-1)
    }

    const handleFileInput = async (e) => {
        const file = e.target.files[0]
        let url = await uploadImage(file)
        updateState({
            ...iState,
            "doc": url?.location
        })
    }

    const handleSubmit = () => {
        const data = {
            firstName: fullName,
            email,
            contact: Number(contact),
            reason,
            message,
            fileAttach: doc
        }
        console.log('data', data)
        if (validation()) {
            dispatch(addSupportEnqueryApi(data)).then((res) => {
                if (res?.payload?.status === 200) {
                    updateState(initialState)
                    navigate(-1)
                }
            })
        }

    }

    console.log('iState', iState)

    const validation = () => {
        let isValid = true;
        let errors = {};

        if (!fullName.trim()) {
            errors.fullName = "Full Name is required";
            isValid = false
        }
        // if (!email.trim()) {
        //     errors.email = "Email is required";
        //     isValid = false
        // } else if (!/\S+@\S+\.\S+/.test(email)) {
        //     errors.email = "Email is invalid";
        //     isValid = false
        // }
        // if (!contact.trim()) {
        //     errors.contact = "Contact Number is required";
        //     isValid = false
        // } else if (!/^[0-9]{10}$/.test(contact)) {
        //     errors.contact = "Contact Number is invalid";
        //     isValid = false
        // }
        if (!reason) {
            errors.reason = "Please select a reason";
            isValid = false
        }
        if (!message.trim()) {
            errors.message = "Message is required";
            isValid = false
        }
        if (!doc) {
            errors.doc = "Please select a file";
            isValid = false
        }
        updateState({
            ...iState,
            error: errors
        })
        return isValid;
    };

    useEffect(() => {
        dispatch(userDataApiSlice()).then((res) => {
            console.log('res', res)
            if (res?.payload?.status === 200) {
                updateState({
                    ...iState,
                    fullName: res?.payload?.data?.name,
                    email: res?.payload?.data?.email,
                    contact: res?.payload?.data?.contact,
                })
                dispatch(progressApi(res?.payload?.data?._id))
            }
        })
    }, [])

    return (
        <div className='BodyArea'>
            <Header />
            <SideNav />
            <div className="WrapperAreaEnd">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow m-0">
                            <a onClick={handleBack} >
                                <i className="fa fa-long-arrow-left" /> Back
                            </a>
                        </div>
                    </div>
                    <div className="AddressDetailsBox">
                        <div className="DetailsBoxNew">
                            <div className="SupportHeading">
                                <h4>Submit your issue to our Support Team</h4>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam a
                                    volutpat lacus. Duis est arcu, tincidunt vel tristique id, dignissim
                                    ac metus. Pellent esque semper ligula massa, ut fringilla ipsum
                                    ullamcorper et. Nunc eleme ntum consequat leo. Suspendisse egestas
                                    felis nibh, id mattis urna sempe r ornare. Sed hendrerit nulla nulla
                                </p>
                            </div>
                            <div className="ArticleReplace">
                                <article>
                                    <aside className="w-100">
                                        <div className="PrefilledArea">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <div className="form-group">
                                                        <label>Full Name</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter First Name"
                                                            name="fullName"
                                                            onChange={handleChange}
                                                            value={fullName}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-4">
                                                    {/* <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Last Name"
                                                        />
                                                    </div> */}
                                                    <div className="form-group">
                                                        <label>Contact</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Phone Number"
                                                            name="contact"
                                                            value={contact}
                                                            onChange={handleChange}
                                                            readOnly
                                                        />
                                                    </div>

                                                </div>
                                                <div className='col-lg-4'>
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter Email"
                                                            name="email"
                                                            onChange={handleChange}
                                                            value={email}
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className='col-lg-4'>
                                                    <div className="form-group">
                                                        <label>Attach*</label>
                                                        <input type="file" className="form-control" name="doc" onChange={handleFileInput} />
                                                        <span style={{ color: 'red' }}>{error.doc}</span>
                                                    </div>
                                                    <div className="form-group">
                                                        <label>Reason</label>
                                                        <select className="form-control" name="reason" value={reason} onChange={handleChange}>
                                                            <option>Select</option>
                                                            <option value="Reason 1">Reason 1</option>
                                                            <option value="Reason 2">Reason 2</option>
                                                            <option value="Reason 3">Reason 3</option>
                                                        </select>
                                                        <span style={{ color: 'red' }}>{error.reason}</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-8'>

                                                    <div className="form-group">
                                                        <label>Message</label>
                                                        <textarea
                                                            className="form-control"
                                                            placeholder="Message"
                                                            rows={6}
                                                            name="message"
                                                            value={message}
                                                            onChange={handleChange}
                                                        />
                                                        <span style={{ color: 'red' }}>{error.message}</span>
                                                    </div>
                                                </div>
                                                <div className='col-lg-12'>
                                                    <div className="Buttons">
                                                        <a onClick={handleSubmit}
                                                            className="Button SaveDetailsBtn"
                                                        >
                                                            Submit
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </aside>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportEnquiry