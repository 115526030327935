import React from 'react'

const BenifitsOfOnline = () => {
    return (
        <div><div className="BenefitsOnlineArea">
            <div className="container">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="row">
                            <div className="col-md-7">
                                <div className="BenefitsImage">
                                    <img src={require('../../Assets/images/benefit-img-2.png')} />
                                </div>
                            </div>
                            <div className="col-md-5">
                                <div className="BenefitsContent">
                                    <h1>
                                        Benefits of <br /> Online Consultation
                                    </h1>
                                    <p>
                                        Enjoy personalized astrological consultations from the
                                        comfort of your home. Our online sessions provide deep,
                                        tailored insights, ensuring privacy and flexibility to fit
                                        your schedule.
                                    </p>
                                    <ul>
                                        <li>
                                            <span>
                                                <img src={require('../../Assets/images/safe.png')} />
                                            </span>
                                            100% Safe Consultations
                                        </li>
                                        <li>
                                            <span>
                                                <img src={require('../../Assets/images/verified.png')} />
                                            </span>
                                            Verified Astrologers
                                        </li>
                                        <li>
                                            <span>
                                                <img src={require('../../Assets/images/personal.png')} />
                                            </span>
                                            Personalised Experience
                                        </li>
                                        <li>
                                            <span>
                                                <img src={require('../../Assets/images/secure.png')} />
                                            </span>
                                            Secure Payments
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default BenifitsOfOnline