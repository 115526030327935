import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const loginApiSlice = createAsyncThunk(
    "loginApi/postloginApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `send-otp`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//====================== verify otp=======================//
export const verifyOtp = createAsyncThunk(
    "verifyOtpApi/postverifyOtp",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `verify-otp`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);
//===================== User Registration ==========================//

export const userRegistration = createAsyncThunk(
    "verifyOtpApi/postverifyOtp",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `create-user`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);
//============== Login by google login=========================//
export const userRegistrationByEmail = createAsyncThunk(
    "verifyUserByEmail/postverifyUserByEmail",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `social-google-auth`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//====================== Email Verification ====================================//
export const emailVerification = createAsyncThunk(
    "verifyemailVerification/postverifyemailVerification",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `send-otp-on-mail`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//================verify email ==================================// 

export const otpOnMail = createAsyncThunk(
    "otpOnMail/postotpOnMail",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `verify-otp-on-mail`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);