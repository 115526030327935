import React, { useEffect } from 'react'
import Header from '../../Header/Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { staticContentListApi } from '../../../Redux/Slices/StaticContent/StaticContent'

const ContactUs = () => {
    const dispatch = useDispatch()
    const staticContent = useSelector((state) => state?.staticContentData?.staticContentData)
    console.log('staticContent', staticContent)


    useEffect(() => {
        dispatch(staticContentListApi('CONTACT_US'))
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <>
                <Header />
                <div className="NewHeader">
                    <div className="container">
                        <div className="BoxContent mb-0">
                            <h1>Contact Us</h1>
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa doloremque veniam dicta hic accusamus quia nisi eum est veritatis iure voluptate a necessitatibus labore praesentium quos in tempore odio asperiores, quidem sunt. Molestias quam corporis enim ab voluptas, fuga, laudantium ducimus exercitationem praesentium, suscipit ea. Earum, blanditiis mollitia. Est dolore aut dolor. Corrupti eius, amet asperiores, dicta soluta nemo numquam ad, voluptates aspernatur quis architecto reprehenderit iusto necessitatibus vero molestiae. Eius illo perferendis perspiciatis possimus sequi rem, voluptates enim ipsa blanditiis impedit in provident minus qui magnam voluptatibus vitae iure, sint quo. Impedit ipsa tempora ut? Sequi consequatur maiores incidunt!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="main">
                    <section>
                        <div className="WhyAstrologerArea">
                            <div className="container">
                                <div className="BoxContent">
                                    {
                                        <p dangerouslySetInnerHTML={{ __html: staticContent?.data?.[0]?.description }} />

                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </>
        </div>
    )
}

export default ContactUs