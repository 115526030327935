import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";
import isLoggedIn from "../../../Utils/IsLoggedIn";

const initialState = {
    openLoginModal: false,
    loggedInStatus: false
};

const footerLoginSlice = createSlice({
    name: 'footerLogin', // Name changed to be more descriptive
    initialState,
    reducers: {
        // You can add reducers here
        openLoginModal: (state) => {
            state.openLoginModal = true; // Opens the modal
        },
        closeLoginModal: (state) => {
            state.openLoginModal = false; // Closes the modal
        },
        setLoggedInStatus: (state, actions) => {
            state.loggedInStatus = actions.payload; // Updates the loggedInStatus
        },
    },
});

// Exporting the actions and the reducer from the slice
export const { openLoginModal, closeLoginModal, setLoggedInStatus } = footerLoginSlice.actions;
export default footerLoginSlice.reducer;