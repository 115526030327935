import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { userDataApiSlice } from '../../Redux/Slices/Profile/userData'
import { useDispatch, useSelector } from 'react-redux'
import { googleLogout } from '@react-oauth/google'
import { Modal } from 'react-bootstrap'
import { setLoggedInStatus } from '../../Redux/Slices/FooterLogin/FooterLogin'

const initialState = {
    fullname: "",
    profileImg: "",
    logoutModal: false
}
const LoggedInProfile = (props) => {
    const [iState, updateState] = useState(initialState)
    const { fullname, profileImg, logoutModal } = iState
    const dispatch = useDispatch()
    const userData = useSelector((state) => state?.userData?.userData)
    const navigate = useNavigate()
    console.log('user DATA', userData)
    console.log('props', props)
    const handleLogout = () => {
        window.localStorage.removeItem('astroTechWeb')
        googleLogout()
        props.callBack({
            ...props.iState,
            loginState: false
        })
        dispatch(userDataApiSlice())
        dispatch(setLoggedInStatus(false));
        navigate('/', { replace: true })
    }

    const handleLogoutModal = () => {
        updateState({ ...iState, logoutModal: true })
    }

    useEffect(() => {
        dispatch(userDataApiSlice())
    }, [])
    return (
        <div>
            <div className="RightNavbar">
                <ul>
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link BellIcon"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <i className="fa-regular fa-bell" />
                        </a>
                        <ul className="dropdown-menu">
                            <li>
                                <a className="dropdown-item">
                                    Reminder: You have an <br /> upcoming appointment
                                    <br /> scheduled..
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item">
                                    Reminder: You have an <br /> upcoming appointment
                                    <br /> scheduled..
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item">
                                    Reminder: You have an <br /> upcoming appointment
                                    <br /> scheduled..
                                </a>
                            </li>
                            <li>
                                <a className="dropdown-item">
                                    Reminder: You have an <br /> upcoming appointment
                                    <br /> scheduled..
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item dropdown">
                        <a
                            className="nav-link ProfileIcon"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span>
                                {/* work here */}
                                <img src={userData?.data?.image} />
                            </span>{" "}
                            {userData?.data?.name}
                        </a>
                        <ul className="dropdown-menu p-0">
                            <li>
                                <Link
                                    to='/profile'
                                    className="dropdown-item"
                                >
                                    {" "}
                                    <span>
                                        <img src={require('../../Assets/images/profile.png')} />
                                    </span>{" "}
                                    Profile
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to='/appointmentManagement'
                                >
                                    {" "}
                                    <span>
                                        <img src={require('../../Assets/images/stickynote.png')} />
                                    </span>{" "}
                                    Appointment
                                </Link>
                            </li>
                            <li>
                                <Link
                                    className="dropdown-item"
                                    to='/payment'
                                >
                                    {" "}
                                    <span>
                                        <img src={require('../../Assets/images/payment.png')} />
                                    </span>{" "}
                                    Payment
                                </Link>
                            </li>
                            <li>
                                <Link
                                    to='/support'
                                    className="dropdown-item"
                                >
                                    {" "}
                                    <span>
                                        <img src={require('../../Assets/images/sms.png')} />
                                    </span>{" "}
                                    Support
                                </Link>
                            </li>
                            <li>
                                <a className="dropdown-item LogOutBtn" onClick={handleLogoutModal}>
                                    {" "}
                                    <span>
                                        <img src={require('../../Assets/images/logout.png')} />
                                    </span>{" "}
                                    Logout
                                </a>
                            </li>
                        </ul>
                    </li>
                    {/* <li class="nav-item">
                      <a class="nav-link AccountBtn"  data-toggle="modal" data-target="#LoginSignUpModal">
                          Login or Sign Up
                      </a>
                  </li> */}
                </ul>
            </div>
            <Modal
                show={logoutModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-body">
                        <a className="CloseModal" onClick={() => updateState({ ...iState, logoutModal: false })}>
                            ×
                        </a>
                        <div className="LogOutModalArea">
                            <span>
                                <img src={require('../../Assets/images/logout.png')} />
                            </span>
                            <h5>Log Out</h5>
                            <p>Are you sure you want to log out?</p>
                            <div className="Buttons TwoButtons">
                                <button className="Button" onClick={() => updateState({ ...iState, logoutModal: false })}>
                                    No
                                </button>
                                <a
                                    type="button"
                                    onClick={handleLogout}
                                    className="Button Red"
                                >
                                    Yes
                                </a>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default LoggedInProfile