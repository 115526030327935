import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import OwlCarousel from 'react-owl-carousel2';

const PremiumServices = () => {
    const premiumservicesList = useSelector((state) => state?.premiumServicesList?.premiumServices)
    console.log('premium services list', premiumservicesList)

    const options = {
        items: 4,
        nav: true,
        rewind: true,
        autoplay: true,
        navText: [
            '<span class="custom-prev-arrow">&#8249;</span>', // Left arrow HTML entity
            '<span class="custom-next-arrow">&#8250;</span>'  // Right arrow HTML entity
        ]
    };

    const events = {
        // onDragged: function(event) {...},
        // onChanged: function(event) {...}
    };
    return (
        <div>
            <div className="tab-pane">
                <div className="CarouselParent">
                    {
                        premiumservicesList?.data?.result?.length ?
                            <OwlCarousel
                                //ref="car" 
                                options={options} events={events} >
                                {
                                    premiumservicesList?.data?.result?.length > 0 ?
                                        premiumservicesList?.data?.result?.map((item) => {
                                            return (
                                                <div className="item">
                                                    <div className="ServiceBoxOuter">
                                                        <Link to='/bookingFirstPage' state={item}>
                                                            <div className="ServiceBoxinner Update">
                                                                <span className="Icon">
                                                                    {item?.serviceIcon && <img src={item?.serviceIcon} height={60} width={60} />}
                                                                </span>
                                                                <div>
                                                                    <h6>{item?.serviceName}</h6>
                                                                    <div className="ViewKundli">
                                                                        <button className="ViewKundliBtn" to='/bookingFirstPage' state={item}>
                                                                            Explore
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </Link>

                                                    </div>
                                                </div>)
                                        }) : "No Service Found"
                                }
                            </OwlCarousel>
                            : null
                    }
                </div>
            </div>
        </div>
    )
}

export default PremiumServices