// src/FacebookLoginButton.js
import React from 'react';
import FacebookLogin from 'react-facebook-login';
import { credAndUrl } from "../../Config/config"
import { useDispatch } from 'react-redux';
import { userRegistrationByEmail } from '../../Redux/Slices/Login/Login';
import { toast } from 'react-toastify';
const FacebookLoginButton = (props) => {

    const dispatch = useDispatch()

    console.log('props', props)

    const responseFacebook = (response) => {
        console.log('response', response)
        try {
            if (response.email) {
                const data = { email: response.email };

                dispatch(userRegistrationByEmail(data))
                    .then((res) => {
                        // Remove console.log, as it's not needed for functionality
                        // console.log('Response of sign up by email', res) 

                        const isUserExist = res?.payload?.data?.isUserExist;
                        const jwtToken = res?.payload?.data?.user?.jwtToken;

                        // Update state based on user existence
                        props.callBack((prevState) => ({
                            ...prevState,
                            loginModal: false,
                            phoneNumberModal: !isUserExist, // Show phone modal if user doesn't exist
                            email: response.email,
                        }));

                        // Store JWT token if user exists
                        if (isUserExist) {
                            window.localStorage.setItem('astroTechWeb', jwtToken);
                        }
                    })
                    .catch((error) => {
                        console.error('Registration error:', error);
                        toast.error('An error occurred during registration. Please try again.');
                    });
            }
        } catch (error) {
            // Consider adding more specific error handling or logging here
            console.error('Facebook login error:', error);
        }
    }

    return (
        <div className="facebook-button-container">
            <FacebookLogin
                appId={credAndUrl.facebookLoginId}
                autoLoad={true}
                fields="name,email,picture"
                callback={responseFacebook}
            />
        </div>

    );
};

export default FacebookLoginButton
