import React from 'react'
import { Link } from 'react-router-dom'

const BookingThirdPage = () => {
    return (
        <div style={{ background: "#f6f7ff", padding: "10px", color: "#000", fontSize: "13px" }}>
            <>
                <div className="StepBoxEnd">
                    <ul>
                        <li className="Complete">
                            <span className="Circle" />
                            <span className="Text">Booking info</span>
                        </li>
                        <li className="Complete">
                            <span className="Circle" />
                            <span className="Text">Calendar Availability</span>
                        </li>
                        <li>
                            <span className="CircleImg">
                                <img src={require('../../Assets/images/endUserImages/first-img.png')} />
                            </span>
                            <span className="Text">Basic Details</span>
                        </li>
                        <li>
                            <span className="Circle Default" />
                            <span className="Text">Payment</span>
                        </li>
                    </ul>
                </div>
                <div className="SmallWrapperBoxEnd Large">
                    <div className="ConsultantService">
                        <div className="row">
                            <div className="col-lg-6 pr-4 border-right">
                                <div className="ModalProfileHeading">
                                    <h4>Profile 1</h4>
                                    <p>
                                        Select the initial booking information to move to the next step.
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label>Existing Profile</label>
                                    <select className="form-control">
                                        <option>select existing profile</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your name"
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Date of Birth</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Time of Birth</label>
                                            <input type="time" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Birth Place</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search and select birth place"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Relation</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter relation"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6 pl-4">
                                <div className="ModalProfileHeading">
                                    <h4>Profile 2</h4>
                                    <p>
                                        Select the initial booking information to move to the next step.
                                    </p>
                                </div>
                                <div className="form-group">
                                    <label>Existing Profile</label>
                                    <select className="form-control">
                                        <option>select existing profile</option>
                                    </select>
                                </div>
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your name"
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Date of Birth</label>
                                            <input type="date" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label>Time of Birth</label>
                                            <input type="time" className="form-control" />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Birth Place</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search and select birth place"
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Relation</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter relation"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <h4
                                    className="Heading"
                                    style={{
                                        borderTop: "1px solid rgba(225, 232, 236, 1)",
                                        paddingTop: 15,
                                        marginTop: 15
                                    }}
                                >
                                    Add Questions
                                </h4>
                                <div className="form-group">
                                    <label>Enter Questions</label>
                                    <textarea
                                        rows={4}
                                        className="form-control"
                                        placeholder="Write your questions"
                                        defaultValue={""}
                                    />
                                </div>
                            </div>
                            <div className="col-lg-5 mx-auto">
                                <div className="Buttons">
                                    <Link className="ContinueBtn" to='/bookingForthPage'>
                                        Continue
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </div>
    )
}

export default BookingThirdPage