import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
const initialState = {
    fullName: "",
    gender: "",
    dob: "",
    timeOfBirth: "",
    time: "",
    placeOfBirth: ""
}

const Numerology = () => {
    const [iState, updateState] = useState(initialState)
    const { fullName, gender, dob, timeOfBirth, time, placeOfBirth } = iState
    const userData = useSelector((state) => state?.userData?.userData)
    console.log('user data', userData)

    useEffect(() => {
        updateState({
            ...iState,
            fullName: userData?.data?.name,
            gender: userData?.data?.gender,
            dob: userData?.data?.dob,
            timeOfBirth: userData?.data?.birthTime,
            placeOfBirth: userData?.data?.birthPlace
        })
    }, [userData?.data?.name])
    return (
        <div
        //className='tab-content'
        ><div className="tab-pane" id="Numerology">
                <div className="container">
                    <div className="FreeKundliHighlights">
                        <h1>Benefits of Numerology</h1>
                        <ul>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-1.png')} />
                                </span>
                                Self-Discovery and Personal Insight
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-2.png')} />
                                </span>
                                Life Path Guidance
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-3.png')} />
                                </span>
                                Spiritual Growth
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-4.png')} />
                                </span>
                                Uncovering Hidden Talents
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-5.png')} />
                                </span>
                                Enhancing Creativity and Innovation
                            </li>
                        </ul>
                        <figure>
                            <img src={require('../../Assets/images/menimg.png')} />
                            {/* <img src="images/menimg.png" /> */}
                        </figure>
                    </div>
                </div>
                <div className="GetDetailedchart">
                    <div className="container">
                        <div className="BoxContent2">
                            <h1>Significance of Numerology</h1>
                            <p>
                                Numerology is the ancient study of numbers and their significance in
                                our lives. It delves into the mystical relationship between numbers
                                and events, aiming to uncover deeper insights about our personalities,
                                life paths, and destinies. By analyzing key numbers derived from our
                                birth dates and names, numerology offers a unique perspective on our
                                strengths, challenges, and potential future trends. This understanding
                                can guide us in making more informed decisions, recognizing our true
                                potential, and aligning our actions with our life purpose, ultimately
                                fostering personal growth and self-awareness.
                            </p>
                        </div>
                    </div>
                    <div className="container">
                        <div className="BoxContent2">
                            <h1>Numerology Numbers</h1>
                            <p>
                                Numerology assigns significance to a total of nine numbers, including
                                two master numbers. Adding the birth date and year results in a sum,
                                which when reduced, yields numbers ranging from 1 to 9, except for 11
                                and 22. Each number from 1 to 9 carries its own unique significance
                                and insights into a person's traits.{" "}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="PanchangArea">
                    <div className="container">
                        <div className="EnterDateLocation">
                            <h1>
                                Explore the meanings of these numerology <br /> numbers below:
                            </h1>
                            <div className="EnterDateBox">
                                <h5>Enter Name &amp; Date of birth To Find Numerology</h5>
                                <div className="CommonForm">
                                    <form>
                                        <div className="row">
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <h6>Name</h6>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        placeholder="Enter Name"
                                                        value={fullName}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-3">
                                                <div className="form-group">
                                                    <h6>Date of Birth</h6>
                                                    <input type="date" className="form-control" value={dob} />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="form-group d-flex align-items-end justify-content-end h-100">
                                                    <a
                                                        href="javascript:void(0);"
                                                        className="SubmitBtn"
                                                        id="NumPrediction"
                                                    >
                                                        Submit
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section>
                    <div className="ConsultSectionOuter mb-0 mt-5">
                        <div className="container">
                            <div className="ConsultSection">
                                <div className="ConsultLeft">
                                    <h3>Consult to get an In-depth analysis of Your Kundli</h3>
                                    <p>
                                        Call or chat with experienced Vedic Astrologers to get accurate
                                        predictions about your future and <br /> personalized solutions
                                        for your problems.
                                    </p>
                                </div>
                                <div className="ConsultRight">
                                    <a href="javascript:void(0);">Match Kundli</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="GetDetailedchart">
                        <div className="container">
                            <div className="BoxContent2">
                                <h1>What is the science of numbers all about?</h1>
                                <p>
                                    The day when a baby enters the world makes an intense impression.
                                    Not just this, the name he gets from his parents makes a huge
                                    difference in his life to be—what will be his personality, how will
                                    he do in his career, etc. But what becomes the prime point of
                                    analysis in numerology is the combination of both the factors—name
                                    as well as the date of birth.
                                </p>
                                <p>
                                    According to numerology, one can predict a number of things,
                                    scenarios, and whatnot about people, their personalities, along with
                                    their future. There are several types of numbers, techniques, and
                                    calculations analyzed in numerology, and they are— Birth Date
                                    Number, Karmic Cycle Number, Life Path Number, Sun Number, etc.
                                </p>
                                <p>
                                    Numerology ahead applies the rules of mathematics in the life of a
                                    person. The numbers involved in numerology range from 1 to 9. These
                                    numbers represent a planet and hold their own distinct features. And
                                    just like astrology, it is seen that these planets influence the
                                    existence of the person in a consequential way. Also, a primary and
                                    a secondary planet governs each individual at the time of their
                                    birth. And post-birth, he/she starts giving vibrations of that
                                    particular number and the planet in charge of that number.
                                </p>
                                <p>
                                    All the major aspects of the life of a person involve his desires,
                                    career, health, finances, etc., depending on the number and the
                                    planets concerned with it. Numerology all has its applications in
                                    the compatibility arena as well. A person can know if their partner
                                    is right for them or not just by looking at their number and knowing
                                    its compatibility with theirs.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="FaqAera">
                        <div className="container">
                            <div className="section-title">
                                <h1>Frequently asked questions</h1>
                            </div>
                            <div className="StaticArea">
                                <div id="accordion">
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#Numcollapse1"
                                            aria-expanded="false"
                                        >
                                            <h4>Q 1. What is numerology?</h4>
                                        </div>
                                        <div id="Numcollapse1" className="panel-collapse collapse in">
                                            <div className="card-body">
                                                <p>
                                                    Numerology is the study of numbers and their mystical
                                                    significance. It examines the relationship between numbers
                                                    and events in an individual's life, aiming to uncover
                                                    insights into their personality, strengths, challenges, and
                                                    destiny.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#Numcollapse2"
                                        >
                                            <h4>Q 2. How is a Life Path Number calculated?</h4>
                                        </div>
                                        <div id="Numcollapse2" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#Numcollapse3"
                                        >
                                            <h4>Q 3. What is a Destiny Number?</h4>
                                        </div>
                                        <div id="Numcollapse3" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#Numcollapse4"
                                        >
                                            <h4>Q 4. How does numerology affect my daily life?</h4>
                                        </div>
                                        <div id="Numcollapse4" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#Numcollapse5"
                                        >
                                            <h4>Q 5. Can numerology predict the future?</h4>
                                        </div>
                                        <div id="Numcollapse5" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#Numcollapse6"
                                        >
                                            <h4>Q 6. What are Master Numbers in numerology?</h4>
                                        </div>
                                        <div id="Numcollapse6" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Numerology