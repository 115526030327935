import React, { useEffect } from 'react'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import { Link } from 'react-router-dom'
import { enqueryListApi } from '../../../Redux/Slices/Support/support'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { progressApi } from '../../../Redux/Slices/Profile/ProgressBarSlice'
import { userDataApiSlice } from '../../../Redux/Slices/Profile/userData'

const Support = () => {
    const dispatch = useDispatch()
    const enqueryList = useSelector((state) => state?.enqueryList?.enqueryList)


    useEffect(() => {
        dispatch(enqueryListApi())
        dispatch(userDataApiSlice()).then((res) => {
            if (res?.payload?.status === 200) {
                console.log("res", res)
                dispatch(progressApi(res?.payload?.data?._id))
            }
        })

    }, [])
    return (
        <div>
            <Header />
            <SideNav />
            <div class="WrapperAreaEnd">
                <div class="WrapperBox">
                    <div class="TitleBox">
                        <h4 class="Title"></h4>
                        <Link class="TitleLink" to="/supportEnquiry">New Support Enquiry</Link>
                    </div>
                    <div>
                        <div class="TableList">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Sr. No</th>
                                        <th>Support Ticket ID</th>
                                        <th>Date of Enquiry</th>
                                        <th>Reason of Enquiry</th>
                                        <th>Enquiry Status</th>
                                        <th>View Enquiry Details</th>
                                        <th>View Admin Notes</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        enqueryList?.data?.result?.map((item, index) => {
                                            return (<tr>
                                                <td>{index + 1}</td>
                                                <td>{item?.supportNo}</td>
                                                <td>{moment(item?.createdAt).format('DD/MM/YYYY')}</td>
                                                <td>{item?.reason}</td>
                                                <td style={{ textTransform: 'none' }}>{item?.enquiryStatus}</td>
                                                <td>
                                                    <div class="Actions justify-content-start">
                                                        <Link class="Blue ml-0" to="/supportEnqueryDetail" state={item}>
                                                            <i class="fa fa-eye"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="Actions justify-content-center" >
                                                        <Link class="Blue ml-0" to='/supportNotes' state={item}>
                                                            <i class="fa fa-eye"></i>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>)
                                        })
                                    }

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Support