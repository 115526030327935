import React from 'react'
import { Link } from 'react-router-dom'

const BookingSecondePage = () => {
    return (
        <div style={{ background: "#f6f7ff", padding: "10px", color: "#000", fontSize: "13px" }}>
            <>
                <div className="StepBoxEnd">
                    <ul>
                        <li className="Complete">
                            <span className="Circle" />
                            <span className="Text">Booking info</span>
                        </li>
                        <li className="Current">
                            <span className="CircleImg">
                                <img src={require('../../Assets/images/endUserImages/first-img.png')} />
                            </span>
                            <span className="Text">Calendar Availability</span>
                        </li>
                        <li>
                            <span className="Circle Default" />
                            <span className="Text">Basic Details</span>
                        </li>
                        <li>
                            <span className="Circle Default" />
                            <span className="Text">Payment</span>
                        </li>
                    </ul>
                </div>
                <div className="SmallWrapperBoxEnd">
                    <div className="ConsultantService">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="Heading">
                                    <h3>Schedule Your Consultation</h3>
                                    <p>
                                        Select the initial booking information to move to the next step.
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Language</label>
                                    <select className="form-control">
                                        <option>--select--</option>
                                        <option>English</option>
                                        <option>Hindi</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="ChooseOptionAreaEnd">
                                    <article>
                                        <aside id="RequestCall">
                                            <div>
                                                <h6>Voice Call (offline)</h6>
                                                <p>Request direct call after filled the basic information.</p>
                                            </div>
                                            <label className="Radio">
                                                <input type="radio" name="Excellent" />
                                                <span className="checkmark" />
                                            </label>
                                            <figure>
                                                <img src={require('../../Assets/images/endUserImages/request-call.png')} />
                                            </figure>
                                        </aside>
                                    </article>
                                    <div className="LightBlueBox">
                                        <p>
                                            When you select this “Request a call” you are just joining our
                                            waiting list for a call. We will get back to you when our
                                            Astrologer will available.
                                        </p>
                                    </div>
                                    <div className="TimeAvailable">
                                        <figure>
                                            <img src={require('../../Assets/images/endUserImages/time-frame.png')} />
                                        </figure>
                                    </div>
                                    <article>
                                        <aside id="ScheduleCall">
                                            <div>
                                                <h6>Audio/Video Conference (online)</h6>
                                                <p>Schedule a time for the call from below slot.</p>
                                            </div>
                                            <label className="Radio">
                                                <input type="radio" name="Excellent" />
                                                <span className="checkmark" />
                                            </label>
                                            <figure>
                                                <img src={require('../../Assets/images/endUserImages/schedule-call.png')} />
                                            </figure>
                                        </aside>
                                    </article>
                                    <div className="DateAvailable">
                                        <figure>
                                            <img src="images/calendar-frame.png" />
                                        </figure>
                                    </div>
                                </div>
                                <div className="col-lg-6 mx-auto">
                                    <div className="Buttons">
                                        <Link className="ContinueBtn" to='/bookingThirdPage'>
                                            Continue
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </div>
    )
}

export default BookingSecondePage