import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { userDataApiSlice } from '../../../Redux/Slices/Profile/userData'
import { useDispatch, useSelector } from 'react-redux'
import { googleLogout } from '@react-oauth/google';
import { Modal } from 'react-bootstrap';
import { setLoggedInStatus } from '../../../Redux/Slices/FooterLogin/FooterLogin';

const initialState = {
    fullname: "",
    profileImg: "",
    logoutModal: false
}
const Header = () => {
    const [iState, updateState] = useState(initialState)
    const { fullname, profileImg, logoutModal } = iState
    const userData = useSelector((state) => state?.userData?.userData)
    console.log('user data', userData)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    console.log('current path', pathname)

    const handleLogoutModal = () => {
        updateState({
            ...iState,
            logoutModal: true
        })
    }
    // const handleLogout = () => {
    //     console.log('handle logout worked')
    //     window.localStorage.removeItem('astroTechWeb')
    //     googleLogout()
    //     dispatch(userDataApiSlice())
    //     navigate('/', { replace: true })
    //     dispatch(setLoggedInStatus(false));
    // }

    const handleLogout = async (e) => {
        console.log('handle logout worked');

        window.localStorage.removeItem('astroTechWeb');

        await googleLogout(); // Wait for googleLogout to complete

        dispatch(userDataApiSlice()); // Dispatch actions
        dispatch(setLoggedInStatus(false));
        console.log('before navigaton')
        navigate('/', { replace: true }); // Navigate after all other actions are completed
        console.log('after navigaton')
    };
    const currentPath = (path) => {
        if (path === '/profile') {
            return 'Profile'
        }
        if (path === '/bookingInfo') {
            return 'Booking Management'
        }
        if (path === '/appointmentManagement') {
            return 'Appointment'
        }
        if (path === '/payment') {
            return 'Payment'
        }
        if (path === '/support') {
            return 'Support'
        }

        if (path === '/supportEnquiry') {
            return 'Raise Enquiry'
        }

        if (path === '/supportEnqueryDetail') {
            return 'Enquiry Detail'
        }

        if (path === '/supportNotes') {
            return 'Resolution Notes'
        }
    }
    return (
        <div>
            <div className="HeaderEnd">
                <div className="HeaderBox">
                    {/* <div className="HeaderLeft">
                    </div> */}
                    <div className="TitleBox" style={{ float: 'left', paddingTop: '20px' }}>
                        <h4 className="Title">{currentPath(pathname)}</h4>
                    </div>
                    <div className="Navigation">

                        <div className="Avater">
                            <a >
                                <figure>
                                    <img src={userData?.data?.image} />
                                </figure>
                                {userData?.data?.name}
                            </a>
                            <ul>
                                <li>
                                    <figure>
                                        <img src={userData?.data?.image} />
                                    </figure>
                                    <h4>
                                        {" "}
                                        {userData?.data?.name}
                                    </h4>
                                </li>
                                <li onClick={handleLogoutModal}>
                                    <a

                                        data-toggle="modal"
                                        data-target="#LogOutModal"
                                    >
                                        <span >
                                            <i className="fa fa-sign-out" />
                                        </span>{" "}
                                        Logout
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="clear" />
                    </div>
                </div>
            </div>
            <Modal
                show={logoutModal}
                className="ModalBox">
                <Modal.Body>
                    <div className="modal-body">
                        <a className="CloseModal" onClick={() => updateState({ ...iState, logoutModal: false })}>
                            ×
                        </a>
                        <div className="LogOutModalArea">
                            <span>
                                <img src={require('../../../Assets/images/endUserImages/logout-icon.png')} />
                            </span>
                            <h5>Log Out</h5>
                            <p>Are you sure you want to log out?</p>
                            <div className="Buttons TwoButtons">
                                <button className="Button" onClick={() => updateState({ ...iState, logoutModal: false })}>
                                    No
                                </button>
                                <a
                                    // type="button"
                                    onClick={handleLogout}
                                    className="Button Red"
                                >
                                    Yes
                                </a>
                            </div>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Header