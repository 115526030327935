import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

//==================== user data
// export const userDataApiSlice = createAsyncThunk(
//     "userData/getuserData",
//     async (payload) => {
//         console.log("payload===>", payload);
//         try {
//             const response = await axiosInstance.get(
//                 `get-profile`
//             );
//             return response.data;
//         } catch (e) {
//             console.log('Error', e)
//         }
//     }
// );

//======================= update profile ==============================//
export const updateProfileApiSlice = createAsyncThunk(
    "updateProfile/postupdateProfile",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.put(
                `edit-user`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);
//==================== country =======================================//
export const countryListApi = createAsyncThunk(
    "countryListApi/getcountryListApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `countries`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const stateListApi = createAsyncThunk(
    "stateListApi/getstateListApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `states/${payload}`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const cityListApi = createAsyncThunk(
    "cityListApi/getcityListApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `cities/${payload.country}/${payload.state}`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    countryList: [],
    stateList: [],
    cityList: [],
    status: false
}
const CountryStateCityListSlice = createSlice({
    name: 'CountryStateCityListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(countryListApi.pending, (state) => {
                state.status = true;
            })
            .addCase(countryListApi.fulfilled, (state, action) => {
                state.status = false;
                state.countryList = action.payload;
            })
            .addCase(countryListApi.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            }).addCase(stateListApi.pending, (state) => {
                state.status = true;
            })
            .addCase(stateListApi.fulfilled, (state, action) => {
                state.status = false;
                state.stateList = action.payload;
            })
            .addCase(stateListApi.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            }).addCase(cityListApi.pending, (state) => {
                state.status = true;
            })
            .addCase(cityListApi.fulfilled, (state, action) => {
                state.status = false;
                state.cityList = action.payload;
            })
            .addCase(cityListApi.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
    }
})

export default CountryStateCityListSlice.reducer;