import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { enqueryNoteListApi } from '../../../Redux/Slices/Support/support'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'
import moment from 'moment'

const SupportNotes = () => {
    const { state } = useLocation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const enqueryNotes = useSelector((state) => state?.enqueryList.noteList)
    console.log('enqueryNotes', enqueryNotes)


    useEffect(() => {
        dispatch(enqueryNoteListApi({ id: state?._id })).then((res) => {
            if (res?.payload?.status === 200) {
                console.log('res', res)
            }
        })
    }, [])
    return (
        <div>
            <Header />
            <SideNav />
            <div className="WrapperAreaEnd">
                <div className="WrapperBox">
                    <div className="TitleBox">
                        <h4 className="Title"></h4>
                        <div className="BackArrow mb-0">
                            <a onClick={() => navigate(-1)}>
                                <i className="fa fa-long-arrow-left" /> Back
                            </a>
                        </div>
                    </div>
                    <div className="Small-Wrapper">
                        <div className="NotificationsBox">
                            <ul>
                                {
                                    enqueryNotes?.data?.[0]?.notes?.map((item) => {
                                        console.log('item', item)
                                        return (<li>
                                            <aside>
                                                <div className="d-flex justify-content-between">
                                                    <h6>Notes :</h6>
                                                    <h6>
                                                        Status Updated By : <span>{item?.updatedBy}</span>
                                                    </h6>
                                                </div>
                                                <p>
                                                    {item?.message}
                                                </p>
                                                <div className="d-flex justify-content-between">
                                                    <h6>
                                                        Status : <span>{item?.updatedStatus}</span>
                                                    </h6>
                                                    <h6>{moment(item?.updatedAt).format('DD/MM/YYYY hh:mm a')}</h6>
                                                </div>
                                            </aside>
                                        </li>)
                                    })
                                }

                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportNotes