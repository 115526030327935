import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const premiumServicesApi = createAsyncThunk(
    "premiumServices/getpremiumServices",
    async () => {
        try {
            const response = await axiosInstance.get(`booking/service-list`);
            return response.data;
        } catch (e) {
            console.log('Error', e);
            throw e;
        }
    }
);

const initialState = {
    premiumServices: [],
    isLoading: false,
    error: null
};

const premiumServicesSlice = createSlice({
    name: 'premiumServicesSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(premiumServicesApi.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(premiumServicesApi.fulfilled, (state, action) => {
                state.isLoading = false;
                state.premiumServices = action.payload;
            })
            .addCase(premiumServicesApi.rejected, (state, action) => {
                state.isLoading = false;
                state.error = {
                    message: action.error.message,
                    status: action.error.response ? action.error.response.status : null
                };
            });
    }
});

export default premiumServicesSlice.reducer;
