import React from 'react'

const HowItWork = () => {
    return (
        <div><div className="HowItWorksArea">
            <div className="section-title">
                <h1>How it works</h1>
                <p>
                    Select your service, enter your birth details, and embark on an
                    enlightening journey. Our intuitive <br /> platform connects you with
                    expert astrologers for personalised insights and guidance.
                </p>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <div className="HowWorksBox">
                            <span className="Icon">
                                <img src={require('../../Assets/images/how-work-1.png')} />
                            </span>
                            <h5>Choose Service</h5>
                            <p>
                                Contrary to popular belief, Lorem Ipsum is not simply random
                                text.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="HowWorksBox">
                            <span className="Icon">
                                <img src={require('../../Assets/images/how-work-2.png')} />
                            </span>
                            <h5>Provide Basic Details</h5>
                            <p>
                                Contrary to popular belief, Lorem Ipsum is not simply random
                                text.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="HowWorksBox">
                            <span className="Icon">
                                <img src={require('../../Assets/images/how-work-3.png')} />
                            </span>
                            <h5>Consult Top Astrologer</h5>
                            <p>
                                Contrary to popular belief, Lorem Ipsum is not simply random
                                text.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3">
                        <div className="HowWorksBox">
                            <span className="Icon">
                                <img src={require('../../Assets/images/how-work-4.png')} />
                            </span>
                            <h5>Get Detailed Report</h5>
                            <p>
                                Contrary to popular belief, Lorem Ipsum is not simply random
                                text.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div></div>
    )
}

export default HowItWork