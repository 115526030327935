import React, { useEffect } from 'react'
import Header from '../../Header/Header'
import Footer from '../Footer'
import { useDispatch, useSelector } from 'react-redux'
import { staticContentListApi } from '../../../Redux/Slices/StaticContent/StaticContent'

const ContactUs = () => {
    const dispatch = useDispatch()
    const staticContent = useSelector((state) => state?.staticContentData?.staticContentData)
    console.log('staticContent', staticContent)


    useEffect(() => {
        dispatch(staticContentListApi('COPYRIGHT'))
        window.scrollTo(0, 0);
    }, [])
    return (
        <div>
            <>
                <Header />
                <div className="NewHeader">
                    <div className="container">
                        <div className="BoxContent mb-0">
                            <h1>Copyright</h1>
                            <p>
                                These terms and conditions of Use (hereinafter referred as “Terms of Usage”)
                                describe and govern the User’s use of the content and services offered by
                                Astrotalk Services Private Limited (Formerly Codeyeti Software Solutions
                                Pvt. Ltd.) through www.astrotalk.com (hereinafter referred as “We”
                                “Astrotalk” “us” “our” “astrotalk application” “Website”)
                            </p>
                        </div>

                    </div>
                </div>
                <div className="main">
                    <section>
                        <div className="WhyAstrologerArea">
                            <div className="container">
                                <div className="BoxContent">
                                    {
                                        <p dangerouslySetInnerHTML={{ __html: staticContent?.data?.[0]?.description }} />

                                    }
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <Footer />
            </>
        </div>
    )
}

export default ContactUs