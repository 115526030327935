import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import isLoggedIn from '../../Utils/IsLoggedIn'
import userDataApiSlice from '../../Redux/Slices/Profile/userData'
import { FamilyMemberListApiSlice } from '../../Redux/Slices/Profile/AddFamilyMember';
import { Nav, Tab } from 'react-bootstrap';
import MatchingKundli from './MatchingKundli';
import Kundi from './Kundi';
import KundliAndMatchMakingTab from './HoroScope/KundliAndMatchMakingTab';
import { Link, useParams } from 'react-router-dom';

const initialState = {
    fullName: "",
    gender: "",
    dob: "",
    timeOfBirth: "",
    time: "",
    placeOfBirth: ""
}
const FreeKundli = () => {
    const [iState, updateState] = useState(initialState)
    const { fullName, gender, dob, timeOfBirth, time, placeOfBirth } = iState
    const userData = useSelector((state) => state?.userData?.userData)
    const familyMemberList = useSelector((state) => state?.familyMemberList?.familyMemberList)
    const { type, type2 } = useParams()

    console.log('type', type, 'type2', type2)



    console.log('family member list', familyMemberList)
    const dispatch = useDispatch()
    console.log('user data', userData)

    console.log('iState', iState)

    useEffect(() => {
        if (userData?.data?.name) {
            updateState({
                ...iState,
                fullName: userData?.data?.name,
                gender: userData?.data?.gender,
                dob: userData?.data?.dob,
                timeOfBirth: userData?.data?.birthTime,
                placeOfBirth: userData?.data?.birthPlace
            })
        } else {
            console.log('iState in free kundli', iState)
            updateState(initialState)
        }

    }, [userData?.data?.name])

    useEffect(() => {
        dispatch(FamilyMemberListApiSlice())
    }, [])

    return (
        <div
        >
            <div className="tab-pane" id="FrereKundli">
                <div className="container">
                    <div className="FreeKundliHighlights">
                        <h1>Free Kundli Highlights</h1>
                        <ul>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-1.png')} />
                                </span>
                                100% Accurate Data
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-2.png')} />
                                </span>
                                Detailed Birth Chart with Predictions
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-3.png')} />
                                </span>
                                Absolutely Free! Unlock Your Zero-Cost Experience.
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-4.png')} />
                                </span>
                                Simple and Easy to understand
                            </li>
                            <li>
                                <span>
                                    <img src={require('../../Assets/images/highlights-5.png')} />
                                </span>
                                Designed by Expert and Experienced Astrologers
                            </li>
                        </ul>
                        <figure>
                            <img src={require('../../Assets/images/menimg.png')} />
                        </figure>
                    </div>
                </div>
                <section>
                    <div className="GetDetailedchart">
                        <div className="container">
                            <div className="BoxContent">
                                <h1>Get Your Detailed Birth Chart</h1>
                                <p>
                                    Looking for your free Kundli from expert astrologers? Then you have
                                    come to the right place. The online free Kundli available on
                                    Astrotalk is a 100% free and authentic free Kundli that has been
                                    prepared after consulting more than 50 expert astrologers on board.
                                    The free Kundli is such that it can give you a glimpse into various
                                    aspects of your life such as your career, love life, marriage,
                                    business and much more. The online Kundli prepared by the free
                                    Kundli software here is no less than any traditional Kundli and can
                                    also be used for purposes like matching making, Kundli matching for
                                    marriage or simply making future predictions.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="MatchingKundliTabs">
                            <KundliAndMatchMakingTab />
                        </div>

                    </div>
                </section>
                <section>
                    <div className="ConsultSectionOuter">
                        <div className="container">
                            <div className="ConsultSection">
                                <div className="ConsultLeft">
                                    <h3>Consult to get an In-depth analysis of Your Kundli</h3>
                                    <p>
                                        Call or chat with experienced Vedic Astrologers to get accurate
                                        predictions about your future and <br /> personalized solutions
                                        for your problems.
                                    </p>
                                </div>
                                <div className="ConsultRight">
                                    <a>Match Kundli</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="container">
                        <div className="BoxContent2">
                            <h1>Description about Kundli</h1>
                            <p>
                                Looking for your free Kundli from expert astrologers? Then you have
                                come to the right place. The online free Kundli available on Astrotalk
                                is a 100% free and authentic free Kundli that has been prepared after
                                consulting more than 50 expert astrologers on board. The free Kundli
                                is such that it can give you a glimpse into various aspects of your
                                life such as your career, love life, marriage, business and much more.
                                The online Kundli prepared by the free Kundli software here is no less
                                than any traditional Kundli and can also be used for purposes like
                                matching making, Kundli matching for marriage or simply making future
                                predictions.
                            </p>
                            <p>
                                Talking about future predictions, the Kundli catered by Astrotalk to
                                you is such that it considers the movement of all the planets in the
                                native's life from the beginning of his or her life till as far as 100
                                years. Doing so helps you understand the reasons behind not only the
                                ongoing circumstances but also what's to come for you in the future.
                                So if in any way you are confused about life, the online Kundli can be
                                your saviour. Having said that, make sure you try the free online
                                Kundli, and let us know what you feel about it.
                            </p>
                        </div>
                    </div>
                </section>
                <section>
                    <div className="FaqAera">
                        <div className="container">
                            <div className="section-title">
                                <h1>Frequently asked questions</h1>
                            </div>
                            <div className="StaticArea">
                                <div id="accordion">
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                        // href="#collapse1"
                                        // aria-expanded="false"
                                        >
                                            <h4>Q 1. What is janam Kundli?</h4>
                                        </div>
                                        <div id="collapse1" className="panel-collapse collapse in">
                                            <div className="card-body">
                                                <p>
                                                    A Janam Kundli, or birth chart, is a chart in astrology
                                                    that's created based on the exact date, place, and time of
                                                    someone's birth. It shows where all the planets, signs, Sun,
                                                    and Moon were positioned at the time of birth. It also
                                                    includes other astrological details about the individual.
                                                    With this information, astrologers can determine the
                                                    person's ascendant and rising sign. Janam Kundli gives
                                                    insight into a person's personality, future, and current
                                                    life situation.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse2"
                                        >
                                            <h4>Q 2. How Can I Get A Free Online Janam Kundli?</h4>
                                        </div>
                                        <div id="collapse2" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-parent="#accordion"
                                            href="#collapse3"
                                        >
                                            <h4>Q 3. What is Dasha in Kundli analysis?</h4>
                                        </div>
                                        <div id="collapse3" className="panel-collapse collapse">
                                            <div className="card-body">
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                                <p>
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                    Aenean euismod bibendum laoreet. Proin gravida dolor sit
                                                    amet lacus accumsan et viverra justo commodo. Proin sodales
                                                    pulvinar sic tempor. Sociis natoque penatibus et magnis dis
                                                    parturient montes, nascetur ridiculus mus. Nam fermentum,
                                                    nulla luctus pharetra vulputate, felis tellus mollis orci,
                                                    sed rhoncus pronin sapien nunc accuan eget.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default FreeKundli