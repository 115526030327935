import React from 'react'

const BookingForthPage = () => {
    return (
        <div style={{ background: "#f6f7ff", padding: "10px", color: "#000", fontSize: "13px" }}>
            <>
                <div className="StepBoxEnd">
                    <ul>
                        <li className="Complete">
                            <span className="Circle" />
                            <span className="Text">Booking info</span>
                        </li>
                        <li className="Complete">
                            <span className="Circle" />
                            <span className="Text">Calendar Availability</span>
                        </li>
                        <li className="Complete">
                            <span className="Circle" />
                            <span className="Text">Basic Details</span>
                        </li>
                        <li className="Current">
                            <span className="CircleImg">
                                <img src={require('../../Assets/images/endUserImages/first-img.png')} />
                            </span>
                            <span className="Text">Payment</span>
                        </li>
                    </ul>
                </div>
                <div className="SmallWrapperBoxEnd">
                    <div className="ConsultantService">
                        <div className="Heading">
                            <h3>Payment</h3>
                            <p>Complete your payment from below to confirm your consultations.</p>
                        </div>
                        <div className="PaymentOptions">
                            <ul>
                                <li>
                                    <a className="active">
                                        <img src={require('../../Assets/images/endUserImages/master-card.png')} />
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <img src={require('../../Assets/images/endUserImages/net-banking.png')} />
                                    </a>
                                </li>
                                <li>
                                    <a >
                                        <img src={require('../../Assets/images/endUserImages/upi.png')} />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Card Number</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your card number"
                                    />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-group">
                                    <label>Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your name"
                                    />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>Expiry Date</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label>CVV</label>
                                    <input type="text" className="form-control" />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-7 mx-auto">
                            <div className="Buttons">
                                <a

                                    className="ContinueBtn"
                                    data-toggle="modal"
                                    data-target="#ConfirmPayment"
                                >
                                    Confirm Payment
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ModalBox">
                    <div
                        id="ConfirmPayment"
                        className="modal fade ExtraSmallModal"
                        role="dialog"
                    >
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <div className="Category">
                                        <a

                                            className="CloseModal New"
                                            data-dismiss="modal"
                                        >
                                            ×
                                        </a>
                                        <div className="CongratsArea">
                                            <figure>
                                                <img src="images/card.png" />
                                            </figure>
                                            <h2>Congrats!</h2>
                                            <p>
                                                Your payment successfully completed <br /> and you can now use
                                                this.
                                            </p>
                                            <div className="Buttons">
                                                <a className="Button">
                                                    Check Booking
                                                </a>
                                                {/* <button type="button" class="Button" data-dismiss="modal">Check Booking</button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        </div>
    )
}

export default BookingForthPage