import React from 'react'
import UpComingAppointment from './UpComingAppointment'
import CompletedAppointments from './CompletedAppointments'
import CancelledAppointments from './CancelledAppointments'
import NoShowAppointments from './NoShowAppointments'
import { Nav, Tab } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Header from '../Header/Header'
import SideNav from '../SideNav/SideNav'

const AppointmentManagement = () => {
    return (
        <div className='BodyArea'>
            <Header />
            <SideNav />
            <div class="WrapperAreaEnd">
                <div class="WrapperBox">
                    <div>
                        {/* <div className="TitleBox">
                            <h4 className="Title">Appointment</h4>
                        </div> */}
                        <Tab.Container id="left-tabs-example" defaultActiveKey={'upcoming'}>
                            <div className="CommonTabs">
                                <Nav variant="tabs" className="nav nav-tabs">
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link" as={Link} eventKey='upcoming'
                                        //to='/commission-management/event' 
                                        >
                                            Upcoming{" "}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link" as={Link} eventKey='completed'
                                        //to='/commission-management/order' 
                                        >
                                            Completed
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link" as={Link} eventKey='cancelled'
                                        //to='/commission-management/order' 
                                        >
                                            Cancelled
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item className="nav-item">
                                        <Nav.Link className="nav-link" as={Link} eventKey='noshow'
                                        //to='/commission-management/order' 
                                        >
                                            New Show
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <Tab.Content className="tab-content">
                                <Tab.Pane className="tab-pane" eventKey='upcoming'>
                                    <UpComingAppointment />
                                </Tab.Pane>
                                <Tab.Pane className="tab-pane" eventKey="cancelled">
                                    <CompletedAppointments />
                                </Tab.Pane>
                                <Tab.Pane className="tab-pane" eventKey="completed">
                                    <CancelledAppointments />
                                </Tab.Pane>
                                <Tab.Pane className="tab-pane" eventKey="noshow">
                                    <NoShowAppointments />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AppointmentManagement