import React from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import ContainerService from './ContainerService'
import { Carousel } from 'react-responsive-carousel';
import PremiumServicesSlider from './PremiumServicesSlider';
import FreeServicesSlider from './FreeServicesSlider';
// import "react-responsive-carousel/lib/styles/carousel.min.css";

const Container = () => {
    return (
        <div>
            <div className="container">
                <div className="TwoServicesArea"
                >
                    <Tab.Container defaultActiveKey="premiumservices" id="left-tabs-example">
                        <div>
                            <Nav className="nav nav-tabs">
                                <Nav.Item >
                                    <Nav.Link eventKey="premiumservices">
                                        <span>
                                            <img src={require('../../Assets/images/premium-icon.png')} />
                                        </span>
                                        Premium Service </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="freeservices">
                                        <span>
                                            <img src={require('../../Assets/images/free-services-2.png')} />
                                        </span>
                                        Free Service</Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                        <Tab.Content >
                            <Tab.Pane eventKey="premiumservices">
                                <PremiumServicesSlider />
                            </Tab.Pane>
                            <Tab.Pane eventKey="freeservices">
                                <FreeServicesSlider />
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </div>
            </div>
        </div>
    )
}

export default Container
