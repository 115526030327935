import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { openLoginModal } from '../../Redux/Slices/FooterLogin/FooterLogin'
import isLoggedIn from '../../Utils/IsLoggedIn'

const Footer = () => {
    const dispatch = useDispatch()
    const { loggedInStatus } = useSelector((state) => state?.footerLoginData)
    console.log('login status', loggedInStatus)


    console.log('sadfsfsfasfsafsafsafsafsagsagdgd')
    return (
        <div>
            <footer>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="FooterLinks">
                                <h4>Horoscope</h4>
                                <ul>
                                    <li>
                                        <a>Today Horoscope</a>
                                    </li>
                                    <li>
                                        <a>Tomorrow's Horoscope</a>
                                    </li>
                                    <li>
                                        <a>Weekly Horoscope</a>
                                    </li>
                                    <li>
                                        <a>Monthly Horoscope</a>
                                    </li>
                                    <li>
                                        <a>Yearly Horoscope</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="FooterLinks">
                                <h4>Important</h4>
                                <ul>
                                    <li>
                                        {" "}
                                        <Link to='/blogPage'>Blog</Link>
                                    </li>
                                    <li>
                                        {" "}
                                        <Link to='/whyAstrology'>Why Astrology</Link>
                                    </li>
                                    <li>
                                        {" "}
                                        <Link to='/freeServices/kundliMatching/matchMaking'>Kundli Matching</Link>
                                    </li>
                                    <li>
                                        {" "}
                                        <Link to='/freeServices/freekundli/kundli'>Free Kundli</Link>
                                    </li>
                                    <li>
                                        {" "}
                                        <Link to='/freeServices/panchang/""'>Panchang</Link>
                                    </li>
                                    <li>
                                        {" "}
                                        <Link to='/freeServices/numerology/""'>Numerology</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="FooterLinks">
                                <h4>Company</h4>
                                <ul>
                                    <li>
                                        <Link to='/aboutUs'>About Us</Link>
                                    </li>
                                    <li>
                                        <Link to='/contactUs'>Contact Us</Link>
                                    </li>
                                    <li>
                                        <Link to='/faq'>FAQs</Link>
                                    </li>
                                    <li>
                                        <Link to='/termsAndConditions'>Terms &amp; Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/copyright">Copyright</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <div className="FooterLinks">
                                <h4>Support</h4>
                                <ul>
                                    <li>
                                        <a >Support@myastrotech.com</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="FooterLinks">
                                <h4>Astrologer</h4>
                                <ul>
                                    <li>
                                        <a href="http://43.204.250.254/astroTechAstrologer/#" target="_blank">
                                            Astrologer Login
                                        </a>
                                    </li>
                                    <li>
                                        <a href="http://43.204.250.254/astroTechAstrologer/#" target="_blank">
                                            Astrologer Registration
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="FooterLinks">
                                <div className="AppGoogleImage">
                                    <a >
                                        <img src={require('../../Assets/images/app-store.png')} />
                                    </a>
                                    <a >
                                        <img src={require('../../Assets/images/google-play.png')} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="FooterBottom">
                                <p>© 2024 Astro Tech</p>
                                <div className="SocialIcons">
                                    <ul>
                                        <li>
                                            <a >
                                                <img src={require('../../Assets/images/facebook.png')} />
                                            </a>
                                        </li>
                                        <li>
                                            <a >
                                                <img src={require('../../Assets/images/linkdin.png')} />
                                            </a>
                                        </li>
                                        <li>
                                            <a >
                                                <img src={require('../../Assets/images/twitter.png')} />
                                            </a>
                                        </li>
                                        <li>
                                            <a >
                                                <img src={require('../../Assets/images/youtube.png')} />
                                            </a>
                                        </li>
                                        <li>
                                            <a>
                                                <img src={require('../../Assets/images/whatsapp.png')} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Footer