import React, { useEffect } from 'react'
import { Nav, Tab } from 'react-bootstrap'
import { Link, useLocation, useParams } from 'react-router-dom'
import FreeKundli from './FreeKundli'
import Horoscopes from './Horoscopes'
import Panchang from './Panchang'
import Numerology from './Numerology'
import KundliMatching from './KundliMatching'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'

const FreeServices = () => {
    const { type, type2 } = useParams()
    console.log('path', type, 'type2', type2)
    const { pathname } = useLocation();
    console.log("pathname", pathname)
    return (
        <div>
            <Header />
            <div className='ServiceHero'>
                <Tab.Container
                    id="left-tabs-example"
                    activeKey={type}
                >
                    <div className="CommonTabs">
                        <div className='container'>
                            <Nav variant="tabs" className="nav nav-tabs">
                                <Nav.Item className="nav-item">
                                    <Nav.Link className="nav-link" as={Link} eventKey='freekundli'
                                        to='/freeServices/freekundli/kundli'
                                    >
                                        <span>
                                            <img src={require('../../Assets/images/free-kundli.png')} />
                                        </span>
                                        Free Kundli{" "}
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item">
                                    <Nav.Link className="nav-link" as={Link} eventKey='horoscopes'
                                        to='/freeServices/horoscopes/""'
                                    >
                                        <span>
                                            <img src={require('../../Assets/images/horoscope.png')} />
                                        </span>
                                        Horoscopes
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item">
                                    <Nav.Link className="nav-link" as={Link} eventKey='panchang'
                                        to='/freeServices/panchang/""'
                                    >
                                        <span>
                                            <img src={require('../../Assets/images/panchang.png')} />
                                        </span>
                                        Panchang
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item">
                                    <Nav.Link className="nav-link" as={Link} eventKey='numerology'
                                        to='/freeServices/numerology/""'
                                    >
                                        <span>
                                            <img src={require('../../Assets/images/numorology.png')} />
                                        </span>
                                        Numerology
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item className="nav-item">
                                    <Nav.Link className="nav-link" as={Link} eventKey='kundliMatching'
                                        to='/freeServices/kundliMatching/matchMaking'
                                    >
                                        <span>
                                            <img src={require('../../Assets/images/free-kundli.png')} />
                                        </span>
                                        Kundli Matching
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                        </div>
                    </div>
                    <Tab.Content>
                        <Tab.Pane className="tab-pane" eventKey='freekundli'>
                            <FreeKundli />
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane" eventKey="horoscopes">
                            <Horoscopes />
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane" eventKey="panchang">
                            <Panchang />
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane" eventKey="numerology">
                            <Numerology />
                        </Tab.Pane>
                        <Tab.Pane className="tab-pane" eventKey="kundliMatching">
                            <KundliMatching />
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>
            <Footer />
        </div>
    )
}

export default FreeServices