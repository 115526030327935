import React from 'react'
import Hero from '../Hero/Hero'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import Container from './Container'
import HowItWork from './HowItWork'
import BenifitsOfOnline from './BenifitsOfOnline'
import Testimonials from './Testimonials'
import Blogs from './Blogs'

const Main = () => {
    return (
        <div>
            <Header />
            <Hero />
            <div className="Main">
                <section>
                    <Container />
                </section>
                <section>
                    <HowItWork />
                </section>
                <section>
                    <BenifitsOfOnline />
                </section>
                <section>
                    <Testimonials />
                </section>
                <section>
                    <Blogs />
                </section>
            </div>
            <Footer />
        </div>
    )
}

export default Main