import { combineReducers } from '@reduxjs/toolkit';

import familyMemberListReducer from '../Redux/Slices/Profile/AddFamilyMember'
import countryStateCityReducer from '../Redux/Slices/Profile/ProfileSlices'
import progressReducer from '../Redux/Slices/Profile/ProgressBarSlice'
import userReducer from '../Redux/Slices/Profile/userData'
import premiumServicesReducer from '../Redux/Slices/PremiumServices/PremiumServices'
import blogReducer from '../Redux/Slices/Blogs/blogs'
import enqueryReducer from '../Redux/Slices/Support/support'
import staticContentReducer from './Slices/StaticContent/StaticContent';
import bannerReducer from './Slices/Banner/banner'
import faqReducer from './Slices/Faq/faq'
import FooterLoginReducer from './Slices/FooterLogin/FooterLogin'

const rootReducer = combineReducers({
    familyMemberList: familyMemberListReducer,
    countryList: countryStateCityReducer,
    progressStatus: progressReducer,
    userData: userReducer,
    premiumServicesList: premiumServicesReducer,
    blogList: blogReducer,
    enqueryList: enqueryReducer,
    staticContentData: staticContentReducer,
    bannerData: bannerReducer,
    faqData: faqReducer,
    footerLoginData: FooterLoginReducer
    // Add more slices here if needed...
})

export default rootReducer