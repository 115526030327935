import axios from 'axios';
import { credAndUrl } from '../Config/config'
// Create an Axios instance
const axiosInstance = axios.create({
    baseURL: credAndUrl.BASE_URL, // Your API base URL
});

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("astroTechWeb");
        if (token) {
            config.headers.Authorization = `${token}`;
        }
        return config;
    },
    (error) => {
        // Handle request error
        return Promise.reject(error);
    }
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
        // Modify response data or handle response here
        console.log('Response form interceptor', response)
        return response;
    },
    (error) => {
        // Handle response error
        console.log('Error in interceptor', error)

        if (error?.response.status === 401) {
            // window.localStorage.removeItem('subaToken')
            // window.location.href = 'http://3.13.196.242/subaAdmin/#/'
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
