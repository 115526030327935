import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../Interceptor";

export const addSupportEnqueryApi = createAsyncThunk(
    "addSupportEnqueryApi/postaddSupportEnqueryApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.post(
                `support/add-enquiry`,
                payload
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

//===================== enquery list ========================//
export const enqueryListApi = createAsyncThunk(
    "enqueryListApi/getenqueryListApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `support/enquiry-list`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

export const enqueryNoteListApi = createAsyncThunk(
    "enqueryNoteListApi/getenqueryNoteListApi",
    async (payload) => {
        console.log("payload===>", payload);
        try {
            const response = await axiosInstance.get(
                `support/view-admin-notes?requestId=${payload?.id}`
            );
            return response.data;
        } catch (e) {
            console.log('Error', e)
        }
    }
);

const initialState = {
    enqueryList: [],
    noteList: [],
    status: false
}
const enqueryListListSlice = createSlice({
    name: 'enqueryListSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(enqueryListApi.pending, (state) => {
                state.status = true;
            })
            .addCase(enqueryListApi.fulfilled, (state, action) => {
                state.status = false;
                state.enqueryList = action.payload;
            })
            .addCase(enqueryListApi.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
            .addCase(enqueryNoteListApi.pending, (state, action) => {
                state.status = true;

            })
            .addCase(enqueryNoteListApi.fulfilled, (state, action) => {
                state.status = false;
                state.noteList = action.payload;
            })
            .addCase(enqueryNoteListApi.rejected, (state, action) => {
                state.status = false;
                state.error = action.error.message;
            })
    }
})

export default enqueryListListSlice.reducer;