import S3FileUpload from "react-s3/lib/ReactS3";
import { credAndUrl } from '../Config/config'
const config = {
    bucketName: "mobileapplications",
    dirName: "photo",
    region: "ap-south-1",
    accessKeyId: credAndUrl.BUCKET_ACCESS_KEY,
    secretAccessKey: credAndUrl.BUCKET_SECREAT_KEY,
};

window.Buffer = window.Buffer || require("buffer").Buffer;


export const uploadImage = async (file) => {
    try {
        const url = await S3FileUpload.uploadFile(file, config)
        return url
    } catch (e) {
        return 'Error in image url conversion'
    }

}
