import React from 'react'
import OwlCarousel from 'react-owl-carousel2';
import { Link } from 'react-router-dom';
//import 'react-owl-carousel2/style.css'; // requires a loader
//import 'react-owl-carousel2/lib/styles.css'; // Import default CSS
// import 'react-owl-carousel2/lib/styles/owl.theme.default.css';

const FreeServicesSlider = () => {


    const options = {
        items: 4,
        nav: true,
        rewind: true,
        autoplay: true,
        navText: [
            '<span class="custom-prev-arrow">&#8249;</span>', // Left arrow HTML entity
            '<span class="custom-next-arrow">&#8250;</span>'  // Right arrow HTML entity
        ]
    };

    const events = {
        // onDragged: function(event) {...},
        // onChanged: function(event) {...}
    };

    return (
        <div>
            <div className="tab-pane">
                <div className="CarouselParent">
                    <div
                    // className="slider-1 owl-div owl-theme"
                    >
                        <OwlCarousel
                            //ref="car" 
                            options={options} events={events} >
                            <div className="item">
                                <div className="ServiceBoxOuter">
                                    <Link to='/freeServices/freekundli'>
                                        <div className="ServiceBoxinner Update">
                                            <span className="Icon">
                                                <img src={require('../../Assets/images/free-kundli.png')} />
                                            </span>
                                            <div>
                                                <h6>Kundli Report</h6>
                                                <div className="ViewKundli">
                                                    <button className="ViewKundliBtn">Explore</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                            <div className="item">
                                <div className="ServiceBoxOuter">
                                    <Link to='/freeServices/horoscopes'>
                                        <div className="ServiceBoxinner Update">
                                            <span className="Icon">
                                                <img src={require('../../Assets/images/detailed-kundli.png')} />
                                            </span>
                                            <div>
                                                <h6>Horoscope</h6>
                                                <div className="ViewKundli">
                                                    <button className="ViewKundliBtn">Explore</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                            <div className="item">
                                <div className="ServiceBoxOuter">
                                    <Link to='/freeServices/panchang'>
                                        <div className="ServiceBoxinner Update">
                                            <span className="Icon">
                                                <img src={require('../../Assets/images/free-service-3.png')} />
                                            </span>
                                            <div>
                                                <h6>Panchang</h6>
                                                <div className="ViewKundli">
                                                    <button className="ViewKundliBtn">Explore</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                            <div className="item">
                                <div className="ServiceBoxOuter">
                                    <Link to='/freeServices/numerology'>
                                        <div className="ServiceBoxinner Update">
                                            <span className="Icon">
                                                <img src={require('../../Assets/images/free-service-1.png')} />
                                            </span>
                                            <div>
                                                <h6>Numerology</h6>
                                                <div className="ViewKundli">
                                                    <button className="ViewKundliBtn">Explore</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                            <div className="item">
                                <div className="ServiceBoxOuter">
                                    <Link to='/freeServices/kundliMatching'>
                                        <div className="ServiceBoxinner Update">
                                            <span className="Icon">
                                                <img src={require('../../Assets/images/kundli-matching.png')} />
                                            </span>
                                            <div>
                                                <h6>Kundli Matching</h6>
                                                <div className="ViewKundli">
                                                    <button className="ViewKundliBtn">Explore</button>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                            </div>
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FreeServicesSlider