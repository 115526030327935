import React from 'react'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import moment from 'moment'

const BlogDetail = () => {
    const { state } = useLocation()
    console.log('state', state)
    return (
        <div>
            <Header />
            <>
                <div className="NewHeader">
                    {/* <div className="container">
                        <div className="BoxContent mb-0">
                            <h1>
                                <p style={{ float: 'right' }}>Author : {state?.authorName}</p>
                                <p dangerouslySetInnerHTML={{ __html: state?.categories?.[0]?.name }} />
                            </h1>
                            <p dangerouslySetInnerHTML={{ __html: state?.content }} />
                        </div>
                    </div> */}
                </div>
                <div className="main">
                    <div className="BlogDetailsBody">
                        <div className="container">
                            <h1 dangerouslySetInnerHTML={{ __html: state?.name }} ></h1>
                            <aside className='align-items-start'>
                                <span className='CategoryBtn'>{state?.categories?.[0]?.name}</span>
                                <div>
                                    <p>Latest blog . {moment(state?.createdAt).format('DD/MM/YYYY')}</p>
                                    <span style={{ background: "none" }}>Author: {state?.authorName}</span>
                                </div>

                            </aside>
                            <figure>
                                <img
                                    src={state?.image}
                                    width={1100}
                                    height={400}
                                    style={{ borderRadius: '20px' }}
                                />

                            </figure>
                        </div>
                    </div>
                    <div className="WhyAstrologerArea pb-3">
                        <div className="container">
                            <div className="BoxContent2">
                                <p dangerouslySetInnerHTML={{ __html: state?.content }} />
                            </div>
                            {/* <div className="BoxContent2">
                                <h1>1. Sagittarius: The Ultimate Explorer</h1>
                                <p>
                                    Sagittarius, represented by the archer, is known for their love of
                                    freedom and exploration. Ruled by Jupiter, the planet of expansion
                                    and adventure, Sagittarians are natural-born travelers. They crave
                                    new experiences, cultural immersion, and the thrill of discovering
                                    the unknown. Their optimistic and adventurous nature often leads
                                    them to far-flung destinations, where they can satisfy their
                                    wanderlust and expand their horizons.
                                </p>
                            </div>
                            <div className="BoxContent2">
                                <h1>2. Gemini: The Curious Wanderer</h1>
                                <p>
                                    Gemini, symbolized by the twins, is ruled by Mercury, the planet of
                                    communication and curiosity. Geminis are known for their restless
                                    minds and insatiable desire to learn and explore. They thrive on
                                    change and diversity, making them eager travelers who seek
                                    intellectual stimulation and new perspectives. Geminis are likely to
                                    hop from one destination to another, soaking up knowledge and
                                    connecting with people from all walks of life.
                                </p>
                            </div> */}
                        </div>
                        <div className="ConsultSectionOuter mt-5">
                            <div className="container">
                                <div className="ConsultSection">
                                    <div className="ConsultLeft">
                                        <h3>Consult to get an In-depth analysis of Your Kundali</h3>
                                        <p>
                                            Call or chat with experienced Vedic Astrologers to get accurate
                                            predictions about your future and <br /> personalized solutions
                                            for your problems.
                                        </p>
                                    </div>
                                    <div className="ConsultRight">
                                        <a href="javascript:void(0);">Match Kundli</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container">
                            {/* <div className="BoxContent2">
                                <h1>3. Aquarius: The Eccentric Adventurer</h1>
                                <p>
                                    Aquarius, the water bearer, is ruled by Uranus, the planet of
                                    innovation and eccentricity. Aquarians are unconventional and
                                    independent souls who march to the beat of their own drum. They are
                                    drawn to unique and offbeat travel experiences that allow them to
                                    express their individuality and humanitarian ideals. Aquarians often
                                    find themselves drawn to progressive and socially conscious
                                    destinations where they can contribute to positive change.
                                </p>
                            </div>
                            <div className="BoxContent2">
                                <h1>4. Pisces: The Romantic Escapist</h1>
                                <p>
                                    Pisces, symbolized by the fish, is ruled by Neptune, the planet of
                                    dreams and illusions. Pisceans are deeply imaginative and sensitive
                                    beings who seek experiences that stir their emotions and ignite
                                    their creativity. They are drawn to mystical and serene destinations
                                    where they can escape from the pressures of everyday life and
                                    connect with their inner selves. Pisceans often find themselves
                                    drawn to seaside retreats, spiritual journeys, and artistic havens
                                    that inspire their soul.
                                </p>
                            </div> */}
                            {/* <div className="BoxContent2 mb-0 mt-5">
                                <h1 className="mb-0">Related Blogs</h1>
                            </div> */}
                        </div>
                        {/* <div className="BlogsArea pt-3 pb-0">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="BlogBox First">
                                            <figure>
                                                <img src="images/blog-1.png" />
                                            </figure>
                                            <figcaption>
                                                <span>Inspiration</span>
                                                <p>Latest blog . July 12, 2023</p>
                                                <h3>8 Creative Ways to Repurpose Your Webinar Content</h3>
                                            </figcaption>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="BlogBox">
                                            <figure>
                                                <img src="images/blog-2.png" />
                                            </figure>
                                            <figcaption>
                                                <span>Inspiration</span>
                                                <p>Latest blog . July 12, 2023</p>
                                                <h3>
                                                    Why Webinars Are the #1 Lead Generation Marketing Strategy,
                                                    You May Not Be Thinking About
                                                </h3>
                                            </figcaption>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="BlogBox Last">
                                            <figure>
                                                <img src="images/blog-3.png" />
                                            </figure>
                                            <figcaption>
                                                <span>Inspiration</span>
                                                <p>Latest blog . July 12, 2023</p>
                                                <h3>8 Creative Ways to Repurpose Your Webinar Content</h3>
                                            </figcaption>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <section></section>
                    <section></section>
                </div>
            </>
            <Footer />
        </div>
    )
}

export default BlogDetail