import React from 'react'
import OwlCarousel from 'react-owl-carousel2';

const Testimonials = () => {
    const options = {
        items: 1,
        // nav: true,
        rewind: true,
        autoplay: true
    };

    const events = {
        // onDragged: function(event) {...},
        // onChanged: function(event) {...}
    };
    return (
        <div><div className="Testimonials">
            <div className="container">
                <div className="row">
                    <div className="col-lg-11 mx-auto">
                        <div className="section-title text-center">
                            <h1>What our clients are saying.</h1>
                            <p>
                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has
                                roots in a piece of classical Latin <br /> literature from 45 BC.
                            </p>
                        </div>

                    </div>
                </div>
                <OwlCarousel
                    //ref="car"
                    className="slider-2 owl-carousel owl-theme"
                    options={options} events={events} >
                    <div className="item">
                        <div className="TestimonialBox">
                            <div className="TestimonialLeft">
                                <figure>
                                    <img src={require('../../Assets/images/testimonial-1.png')} />
                                </figure>
                            </div>
                            <div className="TestimonialRight">
                                <h3>
                                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                    amet sint. aliqua dolor do amet sint. amet sint. aliqua dolor
                                    do amet sint.
                                </h3>
                                <h6>Elisa Grant</h6>
                                <p>Legacy Solutions Engineer</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="TestimonialBox">
                            <div className="TestimonialLeft">
                                <figure>
                                    <img src={require('../../Assets/images/testimonial-2.png')} />
                                </figure>
                            </div>
                            <div className="TestimonialRight">
                                <h3>
                                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                    amet sint. aliqua dolor do amet sint. amet sint. aliqua dolor
                                    do amet sint.
                                </h3>
                                <h6>Elisa Grant</h6>
                                <p>Legacy Solutions Engineer</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="TestimonialBox">
                            <div className="TestimonialLeft">
                                <figure>
                                    <img src={require('../../Assets/images/testimonial-3.png')} />
                                </figure>
                            </div>
                            <div className="TestimonialRight">
                                <h3>
                                    Amet minim mollit non deserunt ullamco est sit aliqua dolor do
                                    amet sint. aliqua dolor do amet sint. amet sint. aliqua dolor
                                    do amet sint.
                                </h3>
                                <h6>Elisa Grant</h6>
                                <p>Legacy Solutions Engineer</p>
                            </div>
                        </div>
                    </div>
                </OwlCarousel>

                {/* <div className="slider-2 owl-carousel owl-theme">


                </div> */}
            </div>
        </div></div>
    )
}

export default Testimonials


{/* <OwlCarousel
                        //ref="car" 
                        options={options} events={events} >
                        
                    </OwlCarousel> */}